import { Component, ViewEncapsulation,ElementRef, EventEmitter, Inject, OnInit, Output, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupModal } from './popup.modal';
import { popupButtonAction, popupChequeStatus, popupHeader, popupImgPath, popupType } from '../../shared/enums/popupEnums';
import { UtilityService } from '../../shared/services/utility.service';
// import { AgentService_A } from '../../shared/proxy-services/AuthService/agent.service';
// import { HealthDataStorageService } from '../../modules/health/health.service';
// import { HealthDataStorage, PremiumDetailsData } from 'src/app/modules/health/health.modal';
// import { HAPModal } from '../../modules/health/products/hap/hap-modal';
import { PopupService } from '../../shared/services/popup.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
// import { fetchPropToModifyResponse } from 'src/app/shared/interface/mypolicy';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { routeEnums } from '../../shared/enums/routeEnums';
// import { TravelDataStorage, TravelPremiumDetails } from 'src/app/modules/health/products/travel/travel.modal';
// import { TravelDataStorageService } from 'src/app/modules/health/products/travel/travel.service';
// import { userEnums } from 'src/app/shared/enums/userEnums';
// import { StorageService } from 'src/app/shared/services/storage.service';
// import { CustomFooterService } from 'src/app/shared/services/custom-footer.service';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss','../../../styles.scss','../../../assets/bootstrap.min.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class PopupComponent {
    @Output() NextButtonValueEmitter = new EventEmitter<{ Type: string, Button: boolean, ButtonType: string }>();
    popupType = popupType;
    popupImgPath = popupImgPath;
    popupHeader = popupHeader;
    popupChequeStatus = popupChequeStatus;
    // healthFormData: HealthDataStorage;
    // premiumDetailsData: PremiumDetailsData;
    // travelFormData: TravelDataStorage;
    // travelPremiumDetailsData: TravelPremiumDetails;
    // finalAddonList: any[];
    // hapDataModal: HAPModal = new HAPModal(); //hap data modal 
    // insuredForm: FormGroup;
    portabilityWavierArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21];
    insuredMembersObj = [];
    // insuredMemberControl: FormArray;
    // portabilityPolicyDetails: fetchPropToModifyResponse;
    // minPortabilityDOJ;
    // maxPortabilityDOJ;
    showSegment: number = 0;
    gst: string = "GST@18%";
    formSubmitted: boolean = false;
    critishieldCoversList = [];
    // critishieldSelectedCover;
    private subscription: Subscription[] = [];
    footerData: any;
    toggleCopy: boolean = false;
    emitMemberIndex = '';
    options = [];
    sliceIndex: number = 4;
    bookFormGroup!: FormGroup;
    isSelected: boolean = false;
    hapRefillingAddons: any;
    productType: string = '';
    schengenStates: any = [];
    europeanStates: any = [];
    pBusinessFilter: string[] = [];
    pPolicyFilter: string[] = [];
    // channelData: ChannelData;
    showPayLater: boolean = true;
    showPayNow: boolean = true;
    renewalDataStorage: any;
    private eventHandled: boolean = false;
    // quoteDetails: ConvertSavedQuotesResponsePayload;
    dataFilter: any;
    dataSearch: any;
  
    constructor(@Inject(MAT_DIALOG_DATA) public dialogData: PopupModal,
      private popupService: PopupService,
      private dialog: MatDialog, public utilityService: UtilityService, 
      // private agentService: AgentService_A,
      // private healthDataStorageService: HealthDataStorageService,
      // private travelDataStorageService: TravelDataStorageService,
      // private storageService: StorageService,
      // private router: Router, private footerService: CustomFooterService,
      // private renewalStorageService: RenewalDataStorageService,
      private renderer: Renderer2,
      private formBuilder: FormBuilder, 
      private matDialogRef: MatDialogRef<any>,
      // private customStepperService: CustomStepperService
      ) {
    }
  
    ngOnInit(): void {
      // this.productType = this.storageService.getAESDecryptedData(userEnums.ProductType);
      // Close all dialog event
      this.popupService.closeDialog$.subscribe(data => {
        if (data) {
          this.closeAllDialog();
        }
      })
      if (this.dialogData.popupType == popupType.search) {
        this.dataSearch = this.dialogData.data;
        
      }
      if (this.dialogData.popupType == popupType.filter) {
        this.dataFilter = this.dialogData.data;
      }
      // if (this.productType?.toUpperCase() == 'TRAVEL') {
      //   this.constructTravelPremiumData();
      //   // if(this.travelFormData.quoteFormData.productName == 'internationalTravel') {
      //   this.schengenStates = this.storageService.getAESDecryptedData(userEnums.SchengenStates);
      //   this.europeanStates = this.storageService.getAESDecryptedData(userEnums.EuropeanStates);
      //   // }
      // } else {
      //   this.constructHealthPremiumData();
      // }
      // //renewal data emit
      // this.subscription.push(this.footerService.showRenewalFooterData$.subscribe(data => {
      //   if (!this.utilityService.isEmptyOrNull(data)) {
      //     this.footerData = data;
      //   }
      // }));
    }
  
    // constructTravelPremiumData() {
    //   this.travelFormData = this.travelDataStorageService.getTravelDataStorage();
    //   this.travelPremiumDetailsData = this.travelDataStorageService.initializePremiumData();
    //   this.travelPremiumDetailsData.basicPremium = Math.floor(parseFloat(this.travelFormData.premiumFormData.basicPremium)).toString();
    //   this.travelPremiumDetailsData.totalTax = Math.floor(parseFloat(this.travelFormData.premiumFormData.totalTax)).toString();
    //   this.travelPremiumDetailsData.totalPremium = this.travelFormData.premiumFormData.totalPremium;
    //   this.travelPremiumDetailsData.numberOfTraveller = this.travelFormData.premiumFormData.numberOfTraveller;
    //   this.travelPremiumDetailsData.totalDays = this.travelFormData.quoteFormData.policyDuration ? parseInt(this.travelFormData.quoteFormData.policyDuration) :
    //     this.travelFormData.quoteFormData.policyDurationInDays;
    //   this.travelPremiumDetailsData.productName = this.travelFormData.quoteFormData.productName;
    //   this.finalAddonList = this.travelFormData.quoteFormData.quoteSummary;
    // }
  
    // constructHealthPremiumData() {
    //   this.healthFormData = this.healthDataStorageService.getHealthDataStorage();
    //   this.quoteDetails = this.storageService.getAESDecryptedData(userEnums.ConverToProposal);
    //   if (this.quoteDetails && this.quoteDetails.Data) {
    //     this.updateSavedQuotesPremiumData();
    //   }
    //   this.premiumDetailsData = this.healthDataStorageService.initializePremiumData();
    //   this.premiumDetailsData.basicPremium = Math.floor(parseFloat(this.healthFormData.premiumFormData.basicPremium)).toString();
    //   this.premiumDetailsData.totalPremium = this.healthFormData.premiumFormData.totalPremium;
    //   this.premiumDetailsData.totalTax = Math.floor(parseFloat(this.healthFormData.premiumFormData.totalTax)).toString();
    //   this.premiumDetailsData.adult = this.healthFormData.quoteFormData.adultDetails.length;
    //   this.premiumDetailsData.child = this.healthFormData.quoteFormData.childDetails.length;
    //   this.premiumDetailsData.tenure = this.healthFormData.premiumFormData.tenure;
    //   this.premiumDetailsData.productName = this.healthFormData.quoteFormData.productName;
    //   // this.premiumDetailsData.coverRange = this.healthFormData.quoteFormData.policyRange;
    //   this.premiumDetailsData.coverRange = this.healthFormData.quoteFormData.applicantAnnualSum;
    //   this.premiumDetailsData.installmentApplicable = this.healthFormData.quoteFormData.InstallmentApplicable == 'Yes';
    //   this.premiumDetailsData.installmentFrequency = this.healthFormData.quoteFormData.InstallmentFrequency;
    //   this.premiumDetailsData.installmentTotalPremium = this.healthFormData.premiumFormData.InstallmentTotalPremium;
    //   this.premiumDetailsData.zone = this.healthFormData.premiumFormData.zone;
  
    //   this.renewalDataStorage = this.renewalStorageService.getRenewalDataStorage();
  
    //   if (this.healthFormData.quoteFormData.subProductType == 20) {
    //     this.fatchPremiumDetailsForHealthBooster();
    //   } else if (this.healthFormData.quoteFormData.subProductType == 23) {
    //     this.fetchPremiumdataForZeroTat();
  
    //   }
    //   else if (this.healthFormData.quoteFormData.subProductType == 38) {
    //     this.fetchPremiumDetailsForCritishield()
    //   }
    //   else if (this.healthFormData.quoteFormData.subProductType == 42) {
    //     this.hapRefillingAddons = this.healthFormData.quoteFormData.hapRefillingSummary;
    //   }
    //   else {
    //     this.fatchPremiumDetails();
    //   }
  
    //   if (this.dialogData.popupType == popupType.search) {
    //     this.dataSearch = this.dialogData.data;
    //   }
    //   if (this.dialogData.popupType == popupType.filter) {
    //     this.dataFilter = this.dialogData.data;
    //   }
    //   this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    //   if(this.channelData) {
    //     if (this.channelData.hasOwnProperty('BFLAG') && (this.channelData.BFLAG == true || this.channelData.BFLAG == "true")) {
    //       this.showPayLater = false;
    //       this.showPayNow = false;
    //     }
    //   }
    // }
  
    applyDropdownValue(option: any, i: number) {
      this.dataSearch.controls[i].value = option.value;
    }
  
    applyDate(event: any, i: number, type: string) {
      if (event && type == 'start') {
        this.dataSearch.controls[i].startDate = event;
      }
      else if (event && type == 'end') {
        this.dataSearch.controls[i].endDate = event;
      }
    }
  
    // getSelectList(event) {
    //   if (event.value == 0) {
    //     this.selectList = this.dialogData.data.healthProductList;
    //     this.isSelected = true;
    //   } else if (event.value == 2) {
    //     this.selectList = this.dialogData.data.travelList;
    //     this.isSelected = true;
    //   } else {
    //     this.isSelected = false;
    //     this.selectList = [];
    //   }
    // }
  
    // updateSavedQuotesPremiumData() {
    //   this.healthFormData.premiumDataSet = true;
    //   this.healthFormData.premiumFormData.basicPremium = this.quoteDetails.Data.BasicPremium;
    //   this.healthFormData.premiumFormData.totalTax = this.quoteDetails.Data.TotalTax;
    //   this.healthFormData.quoteFormData.adultDetails = this.quoteDetails.Data.AdultDetails;
    //   this.healthFormData.quoteFormData.childDetails = this.quoteDetails.Data.ChildDetails;
    //   this.healthFormData.premiumFormData.tenure = this.quoteDetails.Data.Tenure;
    //   this.healthFormData.quoteFormData.productName = this.quoteDetails.Data.ProductName;
    //   this.healthFormData.quoteFormData.applicantAnnualSum = this.quoteDetails.Data.Members[0].SumInsured ? this.quoteDetails.Data.Members[0].SumInsured.toString() : '';
    //   if (this.quoteDetails.Data.SubProductType == 34) {
    //     this.healthFormData.premiumFormData.zone = this.quoteDetails.Data.Zone == 'Zone A' ? 'A' : this.quoteDetails.Data.Zone == 'Zone B' ? 'B' : this.quoteDetails.Data.Zone == 'Zone C' ? 'C' : 'D';
    //   } else if (this.quoteDetails.Data.SubProductType == 42 || this.quoteDetails.Data.SubProductType == 33 || this.quoteDetails.Data.SubProductType == 40) {
    //     this.healthFormData.premiumFormData.zone = this.quoteDetails.Data.Zone == 'Zone_I' ? 'A' : this.quoteDetails.Data.Zone == 'Zone_II' ? 'B' : this.quoteDetails.Data.Zone == 'Zone_III' ? 'C' : '';
    //   }
    //   this.healthFormData.quoteFormData.InstallmentApplicable = this.quoteDetails.Data.SubProductType == 34 ? 'No' : this.quoteDetails.Data.InstallmentApplicable;
    //   this.healthFormData.quoteFormData.InstallmentFrequency = this.quoteDetails.Data.InstallmentFrequency;
    //   this.healthFormData.premiumFormData.InstallmentTotalPremium = this.quoteDetails.Data.InstallmentTotalPremium ? parseInt(this.quoteDetails.Data.InstallmentTotalPremium) : 0;
    //   if(this.quoteDetails.Data.SubProductType == 33) {
    //   this.healthFormData.premiumFormData.totalPremium = this.quoteDetails.Data.TotalPremium;
    //   this.healthFormData.quoteFormData.quoteSummary = this.quoteDetails.Data.Quotesummary;
    //   }
    // }
  
    closeSearchBottomSheet(value: string) {
      if (value == 'close') {
        this.dialog.closeAll();
      } else if (value == 'search') {
        let data = [];
        for (let i of this.dataSearch.controls) {
          if (i.type == 'dropdown') {
            data.push({ type: i.type, value: i.value });
          }
          else if (i.type == 'myDropdown') {
            data.push({ type: i.type, value: i.value });
          }
          else if (i.type == 'myDependentDropdown') {
            data.push({ type: i.type, value: i.list ? i.value : '' });
          }
          else if (i.type == 'rangepicker') {
            data.push({ type: i.type, startDate: i.startDate, endDate: i.endDate });
          }
        }
        this.matDialogRef.close(data);
      }
    }
    applyFilter(isChecked: any, value: string, i: number) {
      value = value.toLowerCase();
      if (isChecked.checked) {
        if (!this.dataFilter[i].filter.includes(value)) {
          this.dataFilter[i].filter.push(value);
        }
      } else {
        const index = this.dataFilter[i].filter.indexOf(value);
        if (index >= 0) {
          this.dataFilter[i].filter.splice(index, 1);
        }
      }
    }
  
    closeFilterBottomSheet(value: string) {
      if (value == 'close') {
        this.dialog.closeAll();
      } else if (value == 'filter') {
        let data = [];
        for (let i of this.dataFilter) {
          data.push({ filter: i.filter });
        }
        this.matDialogRef.close(data);
      }
    }
  
    toggleCopyFunction() {
      this.toggleCopy = true;
      setTimeout(() => {
        this.toggleCopy = false;
      }, 2000);
    }
  
    // fatchPremiumDetailsForHealthBooster() {
    //   let addonsData: any = this.healthFormData.quoteFormData.addonListHB;
    //   let request = this.healthDataStorageService.getHealthDataStorage();
    //   const addonList = addonsData.map((element, index) => {
    //     const addonDetails = request?.quoteFormData?.addonsHB;
    //     const id = `AddOn${index + 1}`;
    //     const obj = {
    //       id,
    //       addonName: element.CoverCode,
    //       addonCoverId: index + 1,
    //       addonCoverCode: element.CoverCode,
    //       coverId: index + 1,
    //       coverText: element.CoverCode,
    //       coverCode: element.CoverCode
    //     };
  
    //     addonDetails.map((selectedElement, selectedElementIndex) => {
  
    //       for (let key in selectedElement) {
    //         if (element['CoverCode'] == key) {
    //           if (!selectedElement['iMember']) {
    //             if (selectedElement[element['CoverCode']]) {
    //               // obj['isSelected'] = true;
    //               obj['isAddonMemberBasis'] = false;
    //               obj[key] = true;
    //             } else {
    //               // obj['isSelected'] = false;
    //               obj['isAddonMemberBasis'] = false;
    //               obj[key] = false;
    //             }
    //           } else {
    //             if (selectedElement[element['CoverCode']]) {
    //               let memberId = selectedElement[`Member${selectedElementIndex + 1}`] == 'Adult1' ? 1 : (selectedElement[`Member${selectedElementIndex + 1}`] == 'Adult2' ? 2 : 3);
    //               if (memberId == 3) {
    //                 // obj['isSelected'] = true;
    //                 obj['isAddonMemberBasis'] = false;
    //                 obj[key] = true;
    //               } else {
    //                 let finalKey = 'AddOn' + key + `Member${memberId}`;
    //                 obj[finalKey] = true;
    //               }
    //             }
    //           }
    //         }
    //       }
  
    //     })
    //     return { ...element, ...obj }
    //   })
  
    //   this.finalAddonList = addonList.filter((item) => item !== null);
    // }
  
    // fatchPremiumDetails() {
    //   let addonsData: any = this.healthFormData.quoteFormData.addonList;
    //   let request = this.healthDataStorageService.getHealthDataStorage();
  
    //   const addonList = addonsData.map((element) => {
    //     const addonDetails = request?.quoteFormData?.addons;
    //     const id = `AddOn${element.CoverId}`;
    //     const isVisible = this.manageVisibility(request, element, addonDetails);
    //     const selectedAddon = addonDetails?.[id];
  
    //     const obj = {
    //       id,
    //       addonName: element.CoverText,
    //       addonCoverId: element.CoverId,
    //       addonCoverCode: element.CoverCode,
    //       isAddonMemberBasis: element.CoverId === 3 || element.CoverId === 8,
    //       isVisible,
    //     };
  
    //     if (selectedAddon) {
    //       if (obj.addonCoverId === 8 || obj.addonCoverId === 3) {
    //         const isSingleAdult = request?.quoteFormData?.adultDetails?.length === 1;
    //         const addOnName = addonDetails[`AddOn${element.CoverId}Members`]?.[0]?.['AddOnName'];
  
    //         if (obj.addonCoverId === 3 && isSingleAdult) {
    //           obj[`AddOn${element.CoverId}Member1`] = true;
    //         } else if (addOnName === "Adult1") {
    //           obj[`AddOn${element.CoverId}Member1`] = true;
    //         }
  
    //         if (
    //           request?.quoteFormData.adultDetails?.length > 1 &&
    //           addonDetails[`AddOn${element.CoverId}Members`]?.length > 1
    //         ) {
    //           const addOnName2 = addonDetails[`AddOn${element.CoverId}Members`][1]['AddOnName'];
    //           if (addOnName2 === "Adult2") {
    //             obj[`AddOn${element.CoverId}Member2`] = true;
    //           }
    //         }
  
    //         if (obj.isVisible) {
    //           return { ...element, ...obj, isSelected: selectedAddon };
    //         }
    //       }
  
    //       if (obj.isVisible) {
    //         return { ...element, ...obj, isSelected: selectedAddon };
    //       }
    //     }
  
    //     return null;
    //   });
  
    //   this.finalAddonList = addonList.filter((item) => item !== null);
    // }
  
    // manageVisibility(request, element, addonDetails) {
    //   let policyTenure = request?.quoteFormData?.policyTenure == "3 Years"
    //   let applicantAnnualSum = (Number(request?.quoteFormData?.applicantAnnualSum) >= this.hapDataModal?.maxSumInsured && element.CoverId == 11);
    //   let c1 = policyTenure && (element.CoverId == 3 || element.CoverId == 4 || element.CoverId == 5) || element.CoverId == 11;
    //   let c2 = policyTenure && !addonDetails['AddOn3'] && (element.CoverId == 4 || element.CoverId == 5);
    //   let c3 = c1 ? false : c2;
    //   let c4 = c3 ? false : true;
    //   return policyTenure && (element.CoverId == 3) || applicantAnnualSum || (addonDetails['AddOn3'] && (element.CoverId == 4 || element.CoverId == 5)) ? true : c4;
    // }
    // fatchPremiumDetails() {
    //   // let addonsData: any = this.healthFormData.quoteFormData.addonList;
    //   // let request = this.healthDataStorageService.getHealthDataStorage();
  
    //   // const addonList = addonsData.map((element) => {
    //   //   const addonDetails = request?.quoteFormData?.addons;
    //   //   const id = `AddOn${element.CoverId}`;
    //   //   const isVisible = this.manageVisibility(request, element, addonDetails);
    //   //   const selectedAddon = addonDetails?.[id];
  
    //   //   const obj = {
    //   //     id,
    //   //     addonName: element.CoverText,
    //   //     addonCoverId: element.CoverId,
    //   //     addonCoverCode: element.CoverCode,
    //   //     isAddonMemberBasis: element.CoverId === 3 || element.CoverId === 8,
    //   //     isVisible,
    //   //   };
  
    //   //   if (selectedAddon) {
    //   //     if (obj.addonCoverId === 8 || obj.addonCoverId === 3) {
    //   //       const isSingleAdult = request?.quoteFormData?.adultDetails?.length === 1;
    //   //       const addOnName = addonDetails[`AddOn${element.CoverId}Members`]?.[0]?.['AddOnName'];
  
    //   //       if (obj.addonCoverId === 3 && isSingleAdult) {
    //   //         obj[`AddOn${element.CoverId}Member1`] = true;
    //   //       } else if (addOnName === "Adult1") {
    //   //         obj[`AddOn${element.CoverId}Member1`] = true;
    //   //       }
  
    //   //       if (
    //   //         request?.quoteFormData.adultDetails?.length > 1 &&
    //   //         addonDetails[`AddOn${element.CoverId}Members`]?.length > 1
    //   //       ) {
    //   //         const addOnName2 = addonDetails[`AddOn${element.CoverId}Members`][1]['AddOnName'];
    //   //         if (addOnName2 === "Adult2") {
    //   //           obj[`AddOn${element.CoverId}Member2`] = true;
    //   //         }
    //   //       }
  
    //   //       if (obj.isVisible) {
    //   //         return { ...element, ...obj, isSelected: selectedAddon };
    //   //       }
    //   //     }
  
    //   //     if (obj.isVisible) {
    //   //       return { ...element, ...obj, isSelected: selectedAddon };
    //   //     }
    //   //   }
  
    //   //   return null;
    //   // });
  
    //   // this.finalAddonList = this.healthFormData.quoteFormData.quoteSummary;
    // }
  
    // CoverAddonList: any[] = [];
    // totalDataTbl: any[] = [];
    // quoteSummaryData: any[] = [];
  
    // filterlist = [];
    // fetchPremiumdataForZeroTat() {
    //   this.CoverAddonList = [];
    //   this.totalDataTbl = [];
    //   this.filterlist = [];
    //   this.quoteSummaryData = [];
    //   this.CoverAddonList = this.healthFormData.quoteFormData.quoteSummary
    //   this.totalDataTbl = this.constructAddonDetailsForSummary_ZeroTat();
    //   this.quoteSummaryData = this.totalDataTbl;
    //   this.filter_Coverlist(this.quoteSummaryData[0].Cover);
  
    // }
    // constructAddonDetailsForSummary_ZeroTat() {
    //   let mergedArray = [];
    //   //Merged All Members covers;
    //   for (let i = 0; i < this.CoverAddonList.length; i++) {
    //     mergedArray = [...mergedArray, ...this.CoverAddonList[i].CoverDetails];
    //   }
    //   const coverNameOccurrences = {};
    //   mergedArray.forEach(item => {
    //     const coverName = item.CoverName;
    //     coverNameOccurrences[coverName] = (coverNameOccurrences[coverName] || 0) + 1;
    //   });
    //   // Convert occurrences to key-value pairs
    //   const coverNameKeyValuePairs = Object.entries(coverNameOccurrences);
    //   const arrayOfObjects = coverNameKeyValuePairs.map(([coverName, count]) => ({ CoverName: coverName, Count: count, Cover: '', allInsured: false, AdultAtatus: [] }));
    //   this.CoverAddonList.forEach((element, i) => {
    //     arrayOfObjects.forEach(coverName => {
    //       const hasDeathBenefit = element.CoverDetails.some(item => item.CoverName === coverName.CoverName)
    //       coverName['AdultAtatus'] = coverName['AdultAtatus'] || [];
    //       coverName['AdultAtatus'].push(hasDeathBenefit);
    //       coverName['Cover'] = '';
    //     })
    //   })
    //   this.CoverAddonList.forEach(element => {
    //     element.CoverDetails.forEach(item => {
    //       arrayOfObjects.forEach(m => {
    //         if (item.CoverName === m.CoverName)
    //           m.Cover = item.Cover;
    //       })
    //     })
    //   });
    //   const updatedData = arrayOfObjects.map(item => ({
    //     ...item,
    //     allInsured: item.AdultAtatus.every(status => status === true)
    //   }));
    //   arrayOfObjects.forEach(ele => {
    //     this.filterlist.push(ele.Cover)
    //   })
    //   this.filterlist = [...new Set(this.filterlist)];
    //   return updatedData.map(item => ({
    //     ...item,
    //     AdultAtatus: item.allInsured ? Array(item.AdultAtatus.length).fill(false) : item.AdultAtatus,
    //   }));
    // }
    // selectedButton: string;
    // filter_Coverlist(cover) {
    //   this.selectedButton = cover;
    //   this.quoteSummaryData = [];
    //   this.totalDataTbl.filter(item => {
    //     if (item.Cover === cover) {
    //       this.quoteSummaryData.push(item);
    //     }
    //   })
  
    // }
  
  
    // manageVisibility(request, element, addonDetails) {
    //   let policyTenure = request?.quoteFormData?.policyTenure == "3 Years"
    //   let applicantAnnualSum = (Number(request?.quoteFormData?.applicantAnnualSum) >= this.hapDataModal?.maxSumInsured && element.CoverId == 11);
    //   let c1 = policyTenure && (element.CoverId == 3 || element.CoverId == 4 || element.CoverId == 5) || element.CoverId == 11;
    //   let c2 = policyTenure && !addonDetails['AddOn3'] && (element.CoverId == 4 || element.CoverId == 5);
    //   return policyTenure && (element.CoverId == 3) || applicantAnnualSum || (addonDetails['AddOn3'] && (element.CoverId == 4 || element.CoverId == 5)) ? true :
    //     (c1 ? false : c2) ? false : true;
    // }
  
    // close() {
    //   if (!this.utilityService.isEmptyOrNull(this.dialogData.buttonAction)) {
    //     if (this.dialogData.buttonAction == popupButtonAction.logout) {
    //       this.agentService.logout();
    //     }
    //     else if (this.dialogData.buttonAction == popupButtonAction.dashboard) {
    //       this.router.navigate([`/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`]);
    //     }
    //     // Initializing myPolicy component again after save edit portability
    //     else if (this.dialogData.description.includes('Proposal No. in Omniflow')) {
    //       this.popupService.emitMyPolicyOnLoadApi(true);
    //     }
    //     else if (this.dialogData.buttonAction == popupButtonAction.memberIndex) {
    //       this.popupService.emitRenewalMemberIndex('');
    //     }
    //     else if (this.dialogData.buttonAction == popupButtonAction.quote) {
    //       this.router.navigate([`/${routeEnums.HEALTH}/${routeEnums.DASHBOARD}`]);
    //       // this.customStepperService.redirectToStep('f1', this.healthFormData.portabilityDataSet)
  
    //     }
    //     this.closeAllDialog();
    //   }
    //   else {
    //     this.closeAllDialog();
    //   }
    // }
  
    // apply() {
    //   if (!this.utilityService.isEmptyOrNull(this.emitMemberIndex)) {
    //     this.popupService.emitRenewalMemberIndex(this.emitMemberIndex);
    //     this.closeAllDialog();
    //   }
    // }
  
    // emitAdult(value) {
    //   this.emitMemberIndex = value;
    // }
  
    // cancelClicked() {
    //   if (this.dialogData.header == popupHeader.refreshData) {
    //     this.popupService.emitRefreshFormData(false);
    //   } else if (this.dialogData.header == popupHeader.confirmlogout || this.dialogData.header == popupHeader.confirmDashboard) {
    //     this.popupService.emitLogoutData(false);
    //   } else if (this.dialogData.header == popupHeader.confirmdelete) {
    //     this.popupService.emitDeleteData(false);
    //   } else if (this.dialogData.header == popupHeader.backbuttonpressed) {
    //     this.popupService.emitBackButtonData(false);
    //   } else {
    //     this.popupService.emitPEDData(false);
    //   }
    //   this.closeAllDialog();
    // }
  
    // proceedClicked() {
    //   if (this.dialogData.header == popupHeader.refreshData) {
    //     this.popupService.emitRefreshFormData(true);
    //   } else if (this.dialogData.header == popupHeader.confirmlogout || this.dialogData.header == popupHeader.confirmDashboard) {
    //     this.popupService.emitLogoutData(true);
    //   } else if (this.dialogData.header == popupHeader.confirmdelete) {
    //     this.popupService.emitDeleteData(true);
    //   } else if (this.dialogData.header == popupHeader.backbuttonpressed) {
    //     this.popupService.emitBackButtonData(true);
    //   }
    //   else {
    //     // If procced clicked we need to emit that user is okay if no ped is selected
    //     this.popupService.emitPEDData(true);
    //   }
    //   this.closeAllDialog();
    // }
  
    // emitEmail(value) {
    //   this.popupService.emitEmailValue(value);
    //   this.closeAllDialog();
    // }
  
    closeAllDialog() {
      // if (this.dialogData.description.includes('EMI')) {
      //   this.popupService.emitEmiData(true);
      // }
      // else if (this.dialogData.description.includes('Cibil')) {
      //   this.popupService.emitCibilData(true);
      // }
      setTimeout(() => {
        this.dialog.closeAll();
        this.ngOnDestroy();
      }, 0);
    }
  
  
    // JourneyEnd() {
    //   this.agentService.logout();
    // }
  
    // paymentData(data: string) {
    //   if (!this.eventHandled) {
    //     this.eventHandled = true;
    //     this.popupService.emitPaymentData(data);
    //     this.closeAllDialog();
    //     this.removeEventHandler();
    //   }
    // }
  
    // handleEvent(data) {
    //   // This will execute the event handling logic
    //   if (!this.eventHandled) {
    //     this.eventHandled = true;
    //     this.popupService.emitPaymentData(data);
    //     this.closeAllDialog(); 
    //   }
    // }
  
    // fetchPremiumDetailsForCritishield() {
    //   // this.healthFormData.quoteFormData['quoteSummary'] = [];
    //   let adultArray = this.healthFormData.quoteFormData['memberCoverDetails'];
    //   let childArray = this.healthFormData.quoteFormData['memberCoverDetailsChild'];
    //   this.getCoverList(adultArray, 'Adult');
    //   this.getCoverList(childArray, 'Child');
    // }
  
    // getCoverList(data, flag) {
    //   if (data.length) {
    //     data.map((member, memberInd) => {
    //       if (member['covers'].length) {
    //         member['covers'].map((cover, coverIndex) => {
    //           if (cover['coverSelected']) {
    //             this.critishieldSelectedCover = cover['coverName'];
    //             cover['AdultStatusFlag'] = false;
    //             cover['ChildStatusFlag'] = false;
    //             cover['AllInsuredFlag'] = true;
    //             this.critishieldCoversList.push(cover);
    //             // if(flag == 'Adult'){
    //             //   this.healthFormData.quoteFormData.adultDetails[memberInd]['CoverDetails'] = cover;
    //             //   this.healthFormData.quoteFormData.adultDetails[memberInd]['RiskCategory'] = this.healthFormData.quoteFormData['riskCategory'][memberInd][`adult${memberInd+1}`];
    //             // }else if(flag == 'Child'){
    //             //   this.healthFormData.quoteFormData.childDetails[memberInd]['CoverDetails'] = cover;
    //             // }
    //           }
    //         })
    //       }
    //     })
    //   }
  
  
    //   // if(flag == 'Adult'){
    //   //   this.healthFormData.quoteFormData['adultDetails'].map((ele,ind)=>{
    //   //     this.healthFormData.quoteFormData['quoteSummary'].push(ele);
    //   //   })
    //   // }else if(flag == 'Child'){
    //   //   this.healthFormData.quoteFormData['childDetails'].map((ele,ind)=>{
    //   //     this.healthFormData.quoteFormData['quoteSummary'].push(ele);
    //   //   })
    //   // }
    // }
  
    // remove(option: string) {
    //   const index = this.options.indexOf(option);
    //   if (index >= 0) {
    //     this.options.splice(index, 1);
    //   }
    //   this.emitAdult(option);
    //   this.apply();
    //   this.dialog.closeAll();
    // }
  
    // showMore() {
    //   this.sliceIndex = this.options.length;
    // }
  
  
    // compareTick(tickSpace, addonNumber) {
    //   tickSpace = tickSpace.toString();
    //   if (this.hapRefillingAddons.summaryAddons[addonNumber].members.includes(tickSpace)) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }
  
    private selectScrollListener!: () => void;
    @ViewChildren(MatSelect)
    selects!: QueryList<MatSelect>;
    handleMatSelectClick() {
      this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
        this.selects.forEach(select => {
          if (select.panelOpen) {
            select.close();
            this.removeMatSelectScrollListener();
          }
        });
      });
    }
  
    removeMatSelectScrollListener() {
      if (this.selectScrollListener) {
        this.selectScrollListener();
      }
    }
  
    ngOnDestroy() {
      this.dialogData = {
        popupType: '',
        imgName: '',
        header: '',
        description: '',
        buttonLabel: '',
        buttonAction: '',
        data: '',
      }
      this.subscription.forEach(subscription => {
        subscription.unsubscribe();
      });
    }
  
}
