<section class="container-fluid mat-typography mt-sm-5 mt-md-5 mt-lg-3" id="host">
    <div class="row">
        <div class="container panaMaincontainer">
            <div class="floatdiv floatingedit" *ngIf="scrolling">
                <img class="imgScss" src="{{imagePathService.scrollToTopImage}}" (click)="scrollToTop()">
            </div>

            <div class="marginbox">
                <!-- Icon and title start -->
                <div class="myTransactionTitle" *ngIf="!isSearchVisible">
                    <div class="bbrheading">
                        <span><img src="{{imagePathService.bbrImg}}" alt=""></span>
                        <span>
                            <div class="fontst">Booked Business Report</div>

                        </span>
                    </div>
                    <div class="d-md-none">
                        <span (click)="openSearchBottomSheet()"><img src="{{imagePathService.EditImg}}" alt="" /></span>
                        <span (click)="searchFilter()"><img src="{{imagePathService.SearchImg}}" alt="" /></span>
                    </div>
                </div>
                <div class="mobilesearch__fieldBBr" *ngIf="isSearchVisible">
                    <div class="mobileinputDivbbr">
                        <input matInput type="text" class="input-group__input placeholder"
                            [cdkTrapFocusAutoCapture]="isSearchVisible" [cdkTrapFocus]="isSearchVisible"
                            [placeholder]="searchPlaceHolder" (input)="queryData()" [(ngModel)]="inputValue"
                            autocomplete="off">
                        <span class="crossclick" (click)="closeClear()">
                            <img src="{{imagePathService.CloseImg}}" alt="">
                        </span>
                    </div>
                </div>
                <!-- Icon and title end -->

                <!-- Desktop View (above 767px) start -->
                <div class="d-none d-md-block">
                    <!-- Input fields card start -->
                    <mat-card class="innerbox borderinnerbox">
                        <div class="row fieldsdiv" [formGroup]="bookFormGroup">

                            <!-- <div class="col-sm-3 form-group">
                                <mat-label class=" input-group__label" for="IMID">IMID</mat-label>
                                <input matInput type="text" class="input-group__input" placeholder="Enter IMID" autocomplete="off"
                                    formControlName="IMID" id="IMID" readonly>
                            </div> -->

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label" for="myInput">Product Type</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productType"
                                    placeholder="Product Type" formControlName="productType"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <ng-container *ngFor="let item of productTypeList; let i = index; let last = last">
                                        <mat-option [value]="item">
                                            {{item | titlecase}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div>

                            <!-- <div class="col-sm-3 form-group" *ngIf="isSelected">
                                <mat-label class="input-group__label" for="myInput">Product Name</mat-label>
                                <mat-select class="input-group__input cstm_select" id="productName"
                                    placeholder="Product Name" formControlName="productName"
                                    (click)="handleMatSelectClick()" (blur)="removeMatSelectScrollListener()">
                                    <ng-container *ngFor="let item of selectList; let i = index; let last = last">
                                        <mat-option [value]="item.productCode">
                                            {{item.productName}}
                                        </mat-option>
                                        <mat-divider *ngIf="!last"></mat-divider>
                                    </ng-container>
                                </mat-select>
                            </div> -->

                            <div class="col-sm-3 form-group">
                                <mat-label class="input-group__label">Select Date</mat-label>
                                <mat-date-range-input class="input-group__input dob " [rangePicker]="picker"
                                    (click)="picker.open()" [min]="minMax.minDate" [max]="minMax.maxDate">
                                    <input matStartDate formControlName="startDate" placeholder="Start date"
                                        (keydown)="preventInput($event)">
                                    <input matEndDate formControlName="endDate" placeholder="End date"
                                        (keydown)="preventInput($event)">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker"
                                    class="hideDefaultImg"></mat-datepicker-toggle>
                                <mat-date-range-picker #picker panelClass="mat-calendar-header"></mat-date-range-picker>
                            </div>

                            <div class="col-sm-3 form-group">
                                <button class=" search-button" [ngClass]="{'positionBtn': !isSelected}"
                                    (click)="onSubmit()">
                                    <span>Submit</span>
                                </button>
                            </div>

                        </div>
                    </mat-card>
                    <!-- Input fields card end -->

                </div>
                <!-- Desktop View (above 767px) end -->

                <!-- Info card start -->
                <mat-card class="innerbox borderinnerbox align-items-center p-3 infoCardColor d-none d-md-flex">
                    <div class="row fieldsdiv">
                        <table>
                            <tr>
                                <td class="vertAlign">
                                    <img src="{{imagePathService.InfoImg}}" class="infoIcon" alt="Info">
                                </td>
                                <td>
                                    We found a total of <span class="font-weight-bold">{{policyCount ? policyCount : "0"
                                        }}</span>
                                    records
                                    with your search
                                    parameters. Your total booked
                                    business report from <span class="font-weight-bold">{{startDate |
                                        date:
                                        'dd MMM,yyyy'}}</span> to
                                    <span class="font-weight-bold">{{endDate | date: 'dd MMM,yyyy'
                                        }}</span>
                                    is <span class="font-weight-bold amountColor">
                                        ₹{{businessAmount ? utilityService.convertStringtoCurrency(businessAmount)
                                        : "0" }}</span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </mat-card>
                <!-- Info card end -->

                <mat-card class="innerbox borderinnerbox p-3 infoCardColor d-md-none">
                    <table class="filterDatatable">
                        <tr>
                            <td [width]="30"><img src="{{imagePathService.calendarRangeImg}}" alt="">
                            </td>
                            <td class="filterlabel">Date Range</td>
                            <td [width]="30">
                                <img src="{{imagePathService.recordImg}}" alt="">
                            </td>
                            <td class="filterlabel">Records</td>
                            <td class="countLabel">{{policyCount}}</td>
                        </tr>
                        <tr>
                            <td colspan="2" class="countLabel">
                                {{startDate |date:'dd MMM,yyyy'}} -
                                {{endDate | date: 'dd MMM,yyyy'}}
                            </td>
                            <td [width]="30"> <img src="{{imagePathService.amountImg}}" alt=""></td>
                            <td class="filterlabel">Amount</td>
                            <td class="countLabel">₹{{utilityService.convertStringtoCurrency(businessAmount)}}</td>
                        </tr>
                    </table>
                </mat-card>

                <!-- Need to fix -->
                <mat-card class="innerbox borderinnerbox">
                    <div class="fieldsdiv">
                        <div class="productmainDiv">
                            <div class="productdiv">
                                <div class="align-items-center">
                                    <span>Product: <span class="font-weight-bold">{{product}}</span></span>
                                </div>
                            </div>
                            <div class="filterdiv">
                                <div class="d-none d-md-block">
                                    <div class="search__fieldBBr">
                                        <div class="inputDivbbr" [ngClass]="{'searchBorder': showCancel}">
                                            <input matInput type="text" class=" placeholder"
                                                [placeholder]="searchPlaceHolder" (input)="queryData()"
                                                [(ngModel)]="inputValue" (focus)="inputFocus()" (blur)="inputBlur()"
                                                autocomplete="off">
                                            <span class="crossclick pr-2" (click)="clearInputField()">
                                                <img src="{{imagePathService.desktopCrossImg}}" alt="">
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- filter functionality -->
                                <div>
                                    <div class="dropdown d-none d-md-block ">
                                        <button matTooltip="Filter" class="dropdown-toggle customDropdown d-none d-md-block" type="button"
                                            id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" >
                                            <!-- (click)="openFilterPopUp()" -->
                                            <div class="toggIcon">
                                                <img src="{{imagePathService.FilterImg}}" alt="">
                                            </div>
                                        </button>
                                        <ul class="dropdown-menu customDropItems" id="filterDropdown">
                                            <li class="dropdownHeading">
                                                <h4>Business type</h4>
                                            </li>
                                            <li class="dropdown-item cs_dropdown-item"
                                                *ngFor="let value of businessFilterList">
                                                <mat-checkbox [checked]="businessFilter.includes(value.toLowerCase())"
                                                    (change)="applyBusinessFilter($event.checked, value)">
                                                    {{ value }}
                                                </mat-checkbox>
                                            </li>
                                            <li>
                                                <mat-divider></mat-divider>
                                            </li>
                                            <li class="dropdownHeading">
                                                <h4>Endorsement type</h4>
                                            </li>
                                            <li class="dropdown-item cs_dropdown-item"
                                                *ngFor="let value of policyFilterList">
                                                <mat-checkbox [checked]="policyFilter.includes(value.toLowerCase())"
                                                    (change)="applyPolicyFilter($event.checked, value)">
                                                    {{ value }}
                                                </mat-checkbox>
                                            </li>
                                        </ul>

                                    </div>
                                    
                                    <div class="d-md-none">
                                        <img src="{{imagePathService.FilterImg}}" alt=""
                                            (click)="openFilterBottomSheet()">
                                    </div>
                                </div>

                                <!-- ends here -->
                                <div class=" d-flex align-items-center">
                                    <mat-button-toggle-group class="custom-toggle-group" (change)="onToggle($event)"
                                        [value]="toggleValue">
                                        <mat-button-toggle matTooltip="Table View" class="custom_togglebtn" id="tableFilterToggle" (mouseover)="changeStyle('tableFilterToggle')"
                                            value="table"><img src="{{imagePathService.tablelistImg}}"
                                                alt=""></mat-button-toggle>
                                        <mat-button-toggle matTooltip="Card View" class="custom_togglebtn" id="cardFilterToggle"
                                            value="card"><img src="{{imagePathService.cardListImg}}"
                                                alt=""></mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>

                            </div>
                        </div>
                    </div>
                </mat-card>

                <!-- <mat-card class="innerbox borderinnerbox">
                    No record found card
                </mat-card> -->

                <!-- Table format start -->
                <app-custom-table *ngIf="toggleValue == 'table'" [reportList]="filteredData" [buttonData]="tableBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="downloadPolicy($event)"
                    [tableOfBBR]="true"></app-custom-table>
                <!-- Table format end -->

                <!-- Card format start -->
                <app-custom-card *ngIf="toggleValue == 'card'" [reportList]="filteredData" [buttonData]="cardBtnInfo"
                    [displayedColumns]="displayCol" (policyNo)="downloadPolicy($event)"
                    [tableOfBBR]="true"></app-custom-card>
                <!-- Card format end -->

            </div>
        </div>
    </div>
</section>
