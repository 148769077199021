import { Injectable } from '@angular/core';
import { EncryptDecryptService } from './encrypt-decrypt.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private encryptDecryptService: EncryptDecryptService) { }

  // Set get AES Data for session storage
  getAESDecryptedData(keyName: string) {
    return sessionStorage.getItem(keyName) ? JSON.parse(this.encryptDecryptService.decryptAES(sessionStorage.getItem(keyName))) : null;
  }

  setAESEncryptedData(key: string, value: any) {
    let encValue: string = this.encryptDecryptService.encryptAES(JSON.stringify(value));
    sessionStorage.setItem(key, encValue);
  }

  // To get set local storage data
  getAESDecryptedDataLocal(keyName: string) {
    return localStorage.getItem(keyName) ? JSON.parse(this.encryptDecryptService.decryptAES(localStorage.getItem(keyName))) : null;
  }

  setAESEncryptedDataLocal(key: string, value: any) {
    let encValue: string = this.encryptDecryptService.encryptAES(JSON.stringify(value));
    localStorage.setItem(key, encValue);
  }
}
// Set get local storage data
@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  get(key: string) {
    return JSON.parse(sessionStorage.getItem(key) || '{}') || {};
  }

  set(key: string, value: any): boolean {
    sessionStorage.setItem(key, JSON.stringify(value));

    return true;
  }

  has(key: string): boolean {
    return !!sessionStorage.getItem(key);
  }

  remove(key: string) {
    sessionStorage.removeItem(key);
  }

  clear() {
    sessionStorage.clear();
  }
}

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {

  clearLocal() {
    localStorage.clear();
  }

  has(key: string): boolean {
    return localStorage.getItem(key) ? true : false;
  }

  get(key: string){
    return JSON.parse(localStorage.getItem(key) || '{}') || {};
  }

  set(key: string, value: any): boolean {
    localStorage.setItem(key, JSON.stringify(value));

    return true;
  }

}
