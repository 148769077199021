import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BBRModule } from './bbr/bbr.module';
import { LayoutModule } from './layout/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS,HttpClientModule } from '@angular/common/http';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BookedBusinessReportComponent } from './bbr/booked-business-report/booked-business-report.component';
import { createCustomElement } from '@angular/elements';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@NgModule({
  imports: [
    BrowserModule,
    BBRModule,
    LayoutModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    provideClientHydration(),
    provideAnimationsAsync()
  ],
})
export class CustomModule implements DoBootstrap{ 
    constructor(
        private injector: Injector
    ) {}
    ngDoBootstrap(appRef: ApplicationRef) {
        if (!customElements.get('bbr-widget')) {
            // Register only if 'bbr-provider' entry is not found in the registry
            
            // Step 3: bbrComponent stores the constructor class
            const bbrComponent = createCustomElement(BookedBusinessReportComponent, {
                injector: this.injector,    // This injector is used to load the component's factory
            });
            
            // Step 4: Registering custom tag 'bbr-provider' with the obtained custom class
            customElements.define('bbr-widget', bbrComponent);
        }
    }
}
