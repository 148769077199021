import { Component, ViewEncapsulation,EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UtilityService } from '../../shared/services/utility.service';
import {ImagePathService} from '../../../..//src/app/shared/services/image-path.service';
@Component({
  selector: 'app-custom-card',
  templateUrl: './custom-card.component.html',
  styleUrls: ['./custom-card.component.scss','../../../styles.scss','../../../assets/bootstrap.min.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomCardComponent {
    @Input() reportList: any = [];
    @Input() buttonData:any = [];
    @Input() filterString: string = '';
    @Input() tableOfBBR:boolean = false;
    @Input() displayedColumns:any = [];
    @Output() policyNo = new EventEmitter<any>();
    pageSizeOptions: number[] = [5, 10, 25, 100];
    pageSize: number = this.pageSizeOptions[0];
    pageIndex: number = 0;
    pagedList: any = this.reportList;
    length: number = this.pagedList.length;
    selectedRows: any = [];
  
    constructor(
       public utilityService: UtilityService,
        public imagePathService:ImagePathService,
  
    ) { }
  
    ngOnChanges() {
      this.pagedList = this.reportList;
      this.length = this.pagedList.length;
      this.pageIndex = 0;
    }
  
    onPageChange(event: PageEvent) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
  
    emitData(data:any, identifier:any) {
      data = { data, identifier };
      this.policyNo.emit(data);
      if (identifier == 'Add To Pay') {
        this.pageSize = this.pageSizeOptions[3];
      }
    }
  
    banColumns(item: string): boolean {
      const colName = ['Action', 'Select']
      if (colName.includes(item)) {
        return false
      }
      return true;
    }
  
    evalConditionForMyPolicy(data:any, condition:any) {
      return eval(condition);
    }
  
    addSpace(inputString: string) {
      if (inputString == 'PendingForUnderWriter') {
        return 'Pending for Approval with UW1';
      }
      return inputString;
    }
  
    // emitSelectedRow(isChecked: boolean, row: any) {
    //   if (isChecked) {
    //     this.selectedRows.push(row);
    //   } else {
    //     if (this.selectedRows.length > 0) {
    //       for (let i = 0; i < this.selectedRows.length; i++) {
    //         if (this.selectedRows[i].PolicyNumber == row.PolicyNumber) {
    //           this.selectedRows.splice(i, 1);
    //         }
    //       }
    //     }
    //   }
    //   this.policyNo.emit({ data: this.selectedRows, identifier: 'Add To Pay' });
    // }
  
  
}
