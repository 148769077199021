import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { map, catchError, concatMap, finalize } from 'rxjs/operators';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { StorageService, SessionStorageService } from '../services/storage.service';
import { environment } from '../../../environments/environment';
import { userEnums } from '../enums/userEnums';
import { Constants } from '../constants/constants';
// import { AgentService_A } from '../proxy-services/AuthService/agent.service';
import { CreateLoginTokenResponse } from '../interface/token';
import { LoaderService } from '../../layout/loader/loader.service';
import { PopupService } from '../services/popup.service';
import { PopupModal } from '../../layout/popup/popup.modal';
// import { AuthService } from '../../../authentication/auth.service';
import { ChannelData } from '../interface/swapPrameters';
import { UtilityService } from '../services/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ApiInterceptorService {
  static accessToken = '';
  ExcludeURLList = [
    "auth/Auth/CreateLoginToken",
  ];
  errorPopupData!: PopupModal;
  channelData!: ChannelData;

  constructor(
    private encryptDecryptService: EncryptDecryptService,
    private storageService: StorageService,
    private sessionService: SessionStorageService,
    private spinner: LoaderService,
    // private agentService_A: AgentService_A,
    private popupService: PopupService,
    // private authService: AuthService,
    private _encryDecryService:EncryptDecryptService,
    private _utilityservics:UtilityService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let reqUrl = request.url.replace(environment.baseURL, "");
    reqUrl = reqUrl.split("?")[0];
    let notifyReqUrl = reqUrl.toLowerCase();
    // this.channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
    // if (!request.url.includes('GetRenewalCount')) {
    //   this.spinner.show();
    // }

    let response: any;
    const req = this.getHeaders(request) ;
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          response = event.body;
          if ((event.body.hasOwnProperty('StatusCode') && event.body.StatusCode == Constants.statusCode_success)) {
            // this.spinner.hide();
            // if (!this.ExcludeURLList.includes(url)) {
            if (event.body.Data == undefined || event.body.Data == "" || event.body.Data == null) {
              return event;
            }

            else {
              if (reqUrl.includes('DownloadPDFGeneric')) {
                let decryptedData = event.body.Data;
                event = event.clone({
                  body: {
                    ...event.body,
                    'Data': decryptedData,
                  }
                });
                return event;
              } else {
                let decryptedData = JSON.parse(this.encryptDecryptService.finalRsaDecryption(event.body.Data));
                event = event.clone({
                  body: {
                    ...event.body,
                    'Data': decryptedData,
                  }
                });
                return event;
              }
            }
            // }

            // If the request is from create login token directly return the event
            // else {
            //   return event;
            // }
          }
          // If any other issue arises
          // else {
          //   this.errorCheckService.checkErrorCode(event.body.StatusCode)
          //   // Here will come our something went wrong popup
          // }
          else {
            // && response.type !== 'application/pdf'
            // if (response.type !== 'application/octet-stream') {
            //   this.errorPopupData = this.popupService.fetchErrorPopupMsg(event.body.StatusDesc);
            //   this.popupService.openGenericPopup(this.errorPopupData);
            // }
          }
        }
        return event;
      }),
      finalize(() => {
        const urlArray = ['Authenticate','SasBookedBusiness','DownloadPDFGeneric'];
        if (!urlArray.some(x => request.url.includes(x))) {
          this.spinner.handleError(response);
        }
        this.spinner.hide();
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');

        //   // this.popupService.openGenericPopup(this.errorPopupData);
        // if (error.status === Constants.statusCode_unauthorized) {
        //   let credentials = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
        //   let sessionReference = this.storageService.getAESDecryptedData(userEnums.SessionReference);
        //   // let swapParameters = this.storageService.getAESDecryptedData(userEnums.SwapParameters);
        //   // let formData = this.storageService.getAESDecryptedData(userEnums.HealthFormData);
        //   let agentData = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
        //   let subProductCode = this.storageService.getAESDecryptedData(userEnums.SubProductCode);
        //   // let isOrion = this.storageService.getAESDecryptedData(userEnums.IsOrion);
        //   // let renewalData = this.storageService.getAESDecryptedData(userEnums.RenewalFormData);
        //   // let channelData = this.storageService.getAESDecryptedDataLocal(userEnums.ChannelData);
        //   let productType = this.storageService.getAESDecryptedData(userEnums.ProductType);
          
        //   if (this.sessionService.has(userEnums.AuthToken)) {
        //     this.sessionService.clear();
        //   }
        //   // this.storageService.setAESEncryptedDataLocal(userEnums.ChannelData, this.channelData);
        //   this.storageService.setAESEncryptedData(userEnums.SessionReference, sessionReference);
        //   this.storageService.setAESEncryptedData(userEnums.LoginCredential, credentials);
        //   // this.storageService.setAESEncryptedData(userEnums.SwapParameters, swapParameters);
        //   // this.storageService.setAESEncryptedData(userEnums.HealthFormData, formData);
        //   this.storageService.setAESEncryptedData(userEnums.AgentDetails, agentData);
        //   this.storageService.setAESEncryptedData(userEnums.SubProductCode, subProductCode);
        //   // this.storageService.setAESEncryptedData(userEnums.IsOrion, isOrion);
        //   // this.storageService.setAESEncryptedData(userEnums.RenewalFormData, renewalData);
        //   // this.storageService.setAESEncryptedData(userEnums.ChannelData, channelData);
        //   this.storageService.setAESEncryptedData(userEnums.ProductType, productType);

        //   return this.agentService_A.login(credentials).pipe(
        //     concatMap((res: CreateLoginTokenResponse) => {

        //       // if (!request.url.includes('GetRenewalCount')) {
        //       //   this.spinner.show();
        //       // }

        //       if (res.StatusCode == Constants.statusCode_success) {
        //         this.storageService.setAESEncryptedData(userEnums.AuthToken, res.Data.Token);
        //         this.storageService.setAESEncryptedData(userEnums.UopqksmlN, credentials.username);

        //         let updatedRequest = this.getHeaders(request);
        //         return next.handle(updatedRequest).pipe(
        //           map((event: HttpEvent<any>) => {
        //             if (event instanceof HttpResponse) {

        //               if (event.body.Data == undefined || event.body.Data == "" || event.body.Data == null) {
        //                 return event;
        //               }

        //               else {
        //                 if (reqUrl.includes('Intranet')) {
        //                   let decryptedData = event.body.Data;
        //                   event = event.clone({
        //                     body: {
        //                       ...event.body,
        //                       'Data': decryptedData,
        //                     }
        //                   });
        //                   this.spinner.hide();
        //                   return event;
        //                 }
        //                 else {
        //                   let decryptedData = JSON.parse(this.encryptDecryptService.finalRsaDecryption(event.body.Data));
        //                   event = event.clone({
        //                     body: {
        //                       ...event.body,
        //                       'Data': decryptedData,
        //                     }
        //                   });
        //                   // this.spinner.hide();
        //                   return event;
        //                 }
        //               }
        //             }
        //             this.authService.check();
        //             return event;
        //           })
        //         )
        //       }
        //       else {
        //         this.spinner.hide();
        //         this.agentService_A.logout();
        //         // If login api only fails then redirect to previous dashboard
        //         // window.location.href = '';
        //         return "";
        //       }
        //     }),
        //     finalize(() => {
        //       this.spinner.handleError(response);
        //       this.spinner.hide();
        //     }),
        //     catchError((refreshError) => {
        //       // If login fails
        //       // If this also fails redirect it to dashboard
        //       // window.location.href  = '';
        //       this.spinner.hide();
        //       return throwError(() => refreshError);
        //     }),
        //   )
        // } else {
        //   this.spinner.hide();
        //   // this.errorPopupData = this.popupService.fetchErrorPopupMsg('');
        //   // this.popupService.openGenericPopup(this.errorPopupData);
        // }
        // If any other status code appears than redirect to dashboard of exisiting users
        // else {
        //   alert("Other api code failure") // This will be replaced by popup or you can redirect
        //   // window.location.href = ''
        // }

        return throwError(() => error);
      })
    );
  }
 

  getHeaders(request:any) {
    let uid = environment.environment == 'sanity' ? 'Uowkjsdjssrme' : 'UserName';
    let req = request.clone({
      'Authorization': '',
      'Access-Control-Allow-Origin': '*'
    });
    // let authToken = this.storageService.getAESDecryptedData(userEnums.AuthToken);
    // let userName = this.storageService.getAESDecryptedData(userEnums.UopqksmlN);
    let authToken = localStorage.getItem('authToken');
      if (authToken) {
        req = request.clone({
          setHeaders: {
            'Content-Type': "application/json",
            'Authorization': authToken ? authToken : '',
            'Access-Control-Allow-Methods': 'GET,POST',
            //  [`${uid}`]: this.encryptDecryptService.finalRsaEncryption(userName),
            // 'AppName': this.encryptDecryptService.finalRsaEncryption('critishield'),
            // 'SessionReference': this.storageService.getAESDecryptedData(userEnums.SessionReference)
          }
        })
      }
    // }
    return req;


    // let headers = {
    //   'Content-Type': "application/json",
    //   // 'Access-Control-Allow-Methods': 'GET,POST',
    //   // [`${uid}`]: this.encryptDecryptService.finalRsaEncryption(userName),
    //   // 'AppName': this.encryptDecryptService.finalRsaEncryption('critishield'),
    //   // 'SessionReference': this.storageService.getAESDecryptedData(userEnums.SessionReference)
    // };
    // let conditionalData;
    // if (req.url.includes('NysaRedirection' || req.url.includes('CreateExternalToken'))) {
    // let utcTime = this._utilityservics.getUTCTime();
    // let encryptionText = `NysaHealth|NysaHealth@123| ${ utcTime }`;
    // let token = this._encryDecryService.PayloadencryptAES(encryptionText);
    // this.storageService.setAESEncryptedData(userEnums.EncryptionText, token);
    // localStorage.setItem('nysaHealthToken', JSON.stringify(token))

    //   conditionalData = {
    //     'NRedirectionAuthorization': this.storageService.getAESDecryptedData(userEnums.EncryptionText)
    //   }
    // } else {
    // let conditionalData = {
    //   'Authorization': authToken || ''
    // }
    // // }
    // Object.assign(headers, conditionalData)

    // if (authToken) {
    //   req = request.clone({
    //     setHeaders: headers
    //   })
    // }
    // // }

    // return req;
  }
}
