let prod = {
  environment: 'production',
  // baseURL: 'https://panaapi.icicilombard.com/apigateway/health/',

  baseURL: 'https://panaapi.icicilombard.com/apigateway/health/',
  //  'https://panaapi.icicilombard.com/health/api/'
  // existing prod url change left
  existingUrl: 'http://ipartnerblue.prodicicilombard.com/mobileagentapi/ipartnermsite/webpage/#/',
  kycUrl: 'https://iltakecare.icicilombard.com/ILTakeCare/KYC/index.html',
  logoutRedirectionUrl: 'https://ipartner.icicilombard.com/WebPages/Login.aspx',
  nysaMotorLoginRedirection : 'https://nysa.icicilombard.com/#/login',
  nysaBancaLoginRedirection : 'https://nysa.icicilombard.com/banca/#/login'
};

// Existing prod:- https://ipartner.icicilombard.com/mobileagentapi/ipartnermsite/webpage/#/swap?
// Existing Blue:-http://ipartnerblue.prodicicilombard.com/mobileagentapi/ipartnermsite/webpage/#/swap?
export const environment = {
  production: true,
  uat: false,
  sanity: false,
  ipAddress: 'NYSAHEALTH',
  ...prod
};
