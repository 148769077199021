<div class="scrollDiv bbrTable mat-typography" *ngIf="dataSource.data.length > 0">
    <table mat-table class="tableSize" [dataSource]="dataSource" recycleRows
        [ngStyle]="{'min-width': tableOfBBR ? '2305px': '1675px'}">
        <ng-container [matColumnDef]="column.columnDef" *ngFor="let column of displayedColumns" [ngSwitch]="true">
            <div *ngSwitchCase="column.columnDef == 'Select'">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element" class="downloadActionImg">
                    <mat-checkbox
                        (change)="emitData({selected: $event.checked, rowData: element, id: element.id}, 'Add To Pay')"
                        [matTooltip]="column.addToPayTooltip" [checked]="element.isChecked"
                        *ngIf="evalConditionForMyPolicy(element, column.addToPayExp ?? true)"></mat-checkbox>
                    <span *ngIf="evalConditionForMyPolicy(element, column.recalExp ?? true)" class="imgPadding">
                        <img [matTooltip]="column.recalTooltip" src="{{column.imgPath}}" alt=""
                            (click)="emitData(element, column.recalTooltip)">
                    </span>
                </td>
            </div>
            <div *ngSwitchCase="column.columnDef != 'Action' && column.columnDef != 'Select'">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element"> {{(column.columnDef != 'Amount') && (column.columnDef !=
                    'TotalPremium') && (column.columnDef != 'Premium') ? column.cell(element) == '' ? '-' :
                    column.columnDef == 'ProposalStatus' ? addSpace(column.cell(element)): column.cell(element) : '₹' +
                    utilityService.convertStringtoCurrency(column.cell(element))}} </td>
            </div>
            <div *ngSwitchCase="column.columnDef == 'Action'">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="downloadActionImg">
                    <!-- <mat-checkbox (change)="emitSelectedRow($event.checked, element)"
                        [matTooltip]="column.addToPayTooltip"
                        *ngIf="evalConditionForMyPolicy(element, column.addToPayExp ?? true)"></mat-checkbox>
                    <span *ngIf="evalConditionForMyPolicy(element, column.recalExp ?? true)" class="imgPadding">
                        <img [matTooltip]="column.recalTooltip" src="{{column.imgPath}}" alt=""
                            (click)="emitData(element, column.tooltip)">
                    </span>
                    <div class="tableaction">
                        <span *ngFor="let btn of buttonData">
                            <span *ngIf="evalConditionForMyPolicy(element, btn.expression ?? true)"><img
                                    [matTooltip]="btn.tooltip" src="{{btn.imgPath}}" alt=""
                                    (click)="emitData(element, btn.label)"> &nbsp;
                            </span>
                        </span>
                    </div>
                </td>
            </div>
            <div *ngSwitchCase="column.columnDef != 'Action' && column.columnDef != 'Select'">
                <th mat-header-cell *matHeaderCellDef> {{column.header}} </th>
                <td mat-cell *matCellDef="let element"> {{(column.columnDef != 'Amount') && (column.columnDef !=
                    'TotalPremium') && (column.columnDef != 'Premium') ? column.cell(element) == '' ? '-' :
                    column.cell(element) : '₹' +
                    utilityService.convertStringtoCurrency(column.cell(element))}} </td>
            </div>
            <div *ngSwitchCase="column.columnDef == 'Action'">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element" class="downloadActionImg"> -->
                    <div class="tableaction">
                        <span *ngFor="let btn of buttonData">
                            <span *ngIf="evalConditionForMyPolicy(element, btn.expression ?? true)"><img
                                    [matTooltip]="btn.tooltip" src="{{btn.imgPath}}" alt=""
                                    (click)="emitData(element, btn.label)"> &nbsp;
                            </span>
                        </span>
                    </div>
                </td>
            </div>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayCol"></tr>
        <tr mat-row *matRowDef="let row; columns: displayCol;"></tr>
    </table>
</div>


<div *ngIf="dataSource.data.length == 0" class="NoRecordFoundMainDiv">
    <mat-card class="d-flex align-items-center NoRecordFound">
        <div class="pl-3 NoRecordFoundData">
            <img src="{{imagePathService.NoRecordFoundImg}}" alt="No Record Found"
                class="noRecordImg mb-3">
            <h3 class="text-center mb-2 paraMessage mat-typography"> No data to show</h3>
            <h4 class="mb-0 messagePara mat-typography"> Try adjusting your search or filter to find your report</h4>
        </div>
    </mat-card>
</div>
<div class="tablePag" *ngIf="dataSource.data.length != 0">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"></mat-paginator>
</div>