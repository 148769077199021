import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Guid } from "guid-typescript";
import { JSEncrypt } from 'jsencrypt';
import * as forge from 'node-forge';

@Injectable({
  providedIn: 'root'
})
export class EncryptDecryptService {
  initialization: string = "HGHVFJVFJJBHJBGC";
  scalar: string = "b766ee71-d6cb-4e51-be70-";
  sea: string = 'MAKV2SPBNI99212I';

  ihcaraE = '-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmhL67tARgaQrMAVGiiSv5lGL2z9JRgi51iBJ5eYH7VJm1LnHOg/Cuv+rKa933FS1L0+Tt8580Eh5bkVBMtCHFmZNFugljx1BSwZJtmymEglQR2mvxOA3q3LjAxJJLstoFMaZJjxyOK+5Y3HxGtWO5nv5iXEA7TIA97TR8S6FBhb0CsnUsdW0jQo7u5qjVYBdccvqnuoYlgtb7t7BdicMHmJFEwaW3iHVsrEoZFiATpwU95OejCYnNvymRzgyq7ZoPAwjlBbRmFly1w2FIvUAYxC+A6F0QIshjVCq9eeXxko9N3aDpyOIpXfVp6kji2a14y4HUgIgAIdlNLaqAeeM8QIDAQAB-----END PUBLIC KEY-----';

  iturhsD = '-----BEGIN PRIVATE KEY-----MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQCdySHvGlSTxOiCTMjR7EAcl4hf6DgEVXfXHu+rf/M33c6jCkcYf6RMCcquDgCENjQe1q0d/zm8odNx73A01KsJS3JgsT8p0lRsyhkT582Mg78Vj2hmMnJY+BjS61uVApoFOnv0mRewhK7LC5DkWQ0fPuqK9CqXowiVuJJGgoPfjSzmHctsmaXei20RawQZuABDPI66Y+jiCaD2ngFtAehfj/oeJWoC4WxMGluY4ubCZC7woxZ2JjVGufSiXrF4CJezdpy+aZqO49lQ0Xc7XgSmW3mPsHm1M4YGzAnm51j2FMw7z3T9o+uMbgTDyZvQqDCs9Uvx2bZxbFDVptbHGoG9AgMBAAECggEAAaI31eB6KgCCxSyZ2X1edl1Sr5mGKo24lkaoxegNSUTXszdaE2S1u2xp7igd21ouhN44plc1tOBt21/J09/OBVd3L8Ca2vcL4dRGDH9DKpzUZIFnjyofct14vLnsNaxnkoovoKsmhDDPfxf+A05xVONwIFZ7i8/hX1MTcqO+mhjGMA/g1/ZusCBhRJCoiIgMklfhi7JevTwR/l4Gulz9U6bNdmXWQk8AG8d57ktNRivTwdajOnODECoo/PR8+9ihZ8wrfz7ovHzF8KgaZW6RaUeWmpFXIu9uguD/qMhh/vbrORD/8qNozSfn1uTDNvq6f6vu0cRo7KEzvjMawMpG8QKBgQDNulazfMtATcwUwHx6iiRf8qxgcnbzRkc05M/roeWzUHvOzDEF/DnAS5gEjb78Z9tYG8s3Aa5zaElNO+9jNg5w2PlEQFZKKok6vll+Cx9S9LZfL4ER0ztBjmtSSFxj49HLGBplbB2eCPQ9r6QDb2W6hEYsvQQ73QZgx29LLTFsMQKBgQDEV6+88QDzjK9ZxUEy7GltRbJY4jmlV2TFlS79cbojbLUXMT6/wxmtjnbxSONM2fpBmCkYCBSQ9sjNnMV17x9GZubfHgABG+UM1qA/FZXFk8PRySUy7CY9X5sft21UlFwFxERYMsnDcappAWVB2tuf54RJI/VrGLLWMVLIlvmnTQKBgEhTOifft5TjxNuG0DxEVIVmyzMdCrR8AIyX5IDyac6gfOY24wU3pxXzfX4cVwhlerd/DfJIpQvtndeJOp+mYW1K2lckRzHLwnLkeJHXVyvS3EqaaMXbfPmPGCvVRKXZcef0kVMWr1Lf0v3cwcxzXVA1MHaJ/PMOwS9E7duAB+ABAoGAV89Xm0q1wDbFHYLom9u7ctFe5/2VBmtFeFA2CcdZFgrCGrM9ewnIbCRw8YxFi9NY5wkkJ8lFWWflen+ImCvvcs8GpfaGzfgpwkoitNP0fb6OLz6kUlfm6j/d4ptoXBFV7oZOgpuEPNKTAg9Lt94npyYltYhNdC5BnSXF/kv3L2kCgYEAyaD1T6ChLSjX0wIJ8KIYnT1WwXHgJXEzI4m9FDlkTYhm4G2UbfBzO4KW1PNi2BbZrmEJBzyzPwlNf8zWtnsuf5smbTBbPi/PdrQLMgiNOaDAdDzynnm3ZXFK/Repq0bIc2dUuMLd8pN4OBiviPGntVM/4awHKXzbEcwSc5WYQoM=-----END PRIVATE KEY-----'

  encryptMod: JSEncrypt;

  constructor() {
    this.encryptMod = new JSEncrypt({
      default_key_size: '2048',
    });
  }

  PayloadencryptAES(text: any, encKey: any = null) {
    if (!encKey) {
      encKey = this.scalar;
    }
    let key = CryptoJS.enc.Utf8.parse(encKey);
    let iv = CryptoJS.enc.Utf8.parse(this.initialization);
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }

  PayloaddecryptAES(text: any, decKey: any = null) {
    if (!decKey) {
      decKey = this.scalar;
    }
    let key = CryptoJS.enc.Utf8.parse(decKey);
    let iv = CryptoJS.enc.Utf8.parse(this.initialization);
    let decrypted = CryptoJS.AES.decrypt(text, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  finalEncryption(text: any) {
    let id = Guid.raw().split('-');
    let key = id[0] + '-' + id[1] + '-' + id[2] + '-' + id[3] + '-';
    let cypherText1 = this.PayloadencryptAES(JSON.stringify(text), key);
    let finalText = cypherText1 + '|' + key;

    return this.PayloadencryptAES(finalText);
  }

  finalEncryptionNew(text: any) {
    let id = Guid.raw().split('-');
    let key = id[0] + '-' + id[1] + '-' + id[2] + '-' + id[3] + '-';
    let cypherText1 = this.PayloadencryptAES((text), key);
    let finalText = cypherText1 + '|' + key;

    return this.PayloadencryptAES(finalText);

  }

  finalDecryption(text: any) {
    let plainText = this.PayloaddecryptAES(text);
    let dataDivide = plainText.split('|');
    return this.PayloaddecryptAES(dataDivide[0], dataDivide[1]);
  }

  encryptAES(msg: string, pass = this.sea): string {
    var keySize = 256;
    var ivSize = 128;
    var saltSize = 256;
    var iterations = 1000;
    // return CryptoJS.AES.encrypt(value, secretKey.trim()).toString();
    var salt = CryptoJS.lib.WordArray.random(saltSize / 8);
    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: iterations
    });
    var iv = CryptoJS.lib.WordArray.random(ivSize / 8);
    var encrypted = CryptoJS.AES.encrypt(msg, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    });
    var encryptedHex = this.base64ToHex(encrypted.toString());
    var base64result = this.hexToBase64(`${salt}` + iv + encryptedHex);
    return base64result;
  }

  decryptAES(transitmessage: any, pass = this.sea) {
    var keySize = 256;
    var ivSize = 128;
    var saltSize = 256;
    var iterations = 1000;
    var hexResult = this.base64ToHex(transitmessage)
    var salt = CryptoJS.enc.Hex.parse(hexResult.substr(0, 64));
    var iv = CryptoJS.enc.Hex.parse(hexResult.substr(64, 32));
    var encrypted = this.hexToBase64(hexResult.substring(96));

    var key = CryptoJS.PBKDF2(pass, salt, {
      keySize: keySize / 32,
      iterations: iterations
    });
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    })
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  // hexToBase64(str: any) {
  //   return btoa(String.fromCharCode.apply(null,
  //     str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
  //   );
  // }

  hexToBase64(str: any) {
    const cleanedString = str
      .replace(/[\r\n]/g, "")
      .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
      .replace(/ +$/, "")
      .split(" ")
      .map((hex:any) => String.fromCharCode(parseInt(hex, 16)))
      .join("");
    return btoa(cleanedString);
  }

  base64ToHex(str: string) {
    for (var i = 0, bin = atob(str.replace(/[ \r\n]+$/, "")), hex = []; i < bin.length; ++i) {
      var tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) tmp = "0" + tmp;
      hex[hex.length] = tmp;
    }
    return hex.join("");
  }

  finalRsaEncryption(text: any) {

    let id = Guid.raw().split('-');
    let key = id[0] + '-' + id[1] + '-' + id[2] + '-' + id[3] + '-';
    text = typeof (text) == 'object' ? JSON.stringify(text) : text;
    let encryptedRequest = this.PayloadencryptAES(text, key);
    let encryptedGUID = this.RSAEncrypt(key);
    return encryptedRequest + 'a|z' + encryptedGUID;

  }

  finalRsaDecryption(res:any) {
    let splitResponse = res.split('a|z');
    let decryptedGuid = this.RSADecrypt(splitResponse[1]);
    let decryptedRes = this.PayloaddecryptAES(splitResponse[0], decryptedGuid);
    return decryptedRes;
  }



  RSAEncrypt(text:any) {
    const publicKey = forge.pki.publicKeyFromPem(this.ihcaraE);
    const encryptedData = publicKey.encrypt(text, 'RSA-OAEP');
    const base64EncryptedData = forge.util.encode64(encryptedData);
    return base64EncryptedData;
  }

  RSADecrypt(res:any) {

    const privateKey = forge.pki.privateKeyFromPem(this.iturhsD);
    const base64DecryptedData = forge.util.decode64(res);
    const decryptedData = privateKey.decrypt(base64DecryptedData, 'RSA-OAEP');
    return decryptedData;
  }


}
