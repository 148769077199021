import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  constructor(private http: HttpClient) { }

  genericService<T>(endpoint: string, data?: Object | string | null, responseType?: any, headers?: HttpHeaders): Observable<T> {
    return this.http.post<T>(endpoint, data, { responseType: responseType, headers: headers });
  }

  downloadFile(url: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/pdf',
      'responseType': 'blob',
    });

    return this.http.get(url, { headers, responseType: 'blob' });
  }

  genericGetService<T>(endpoint: string): Observable<T> {
    return this.http.get<T>(endpoint);
  }

}
