import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { EncryptDecryptService } from '../services/encrypt-decrypt.service';
import { StorageService } from '../services/storage.service';
import { userEnums } from '../enums/userEnums';
import { UtilityService } from '../services/utility.service';
@Injectable({
  providedIn: 'root'
})
export class ApiRequestInteceptorService {
  constructor(
    private encryptDecryptService: EncryptDecryptService,
    private storageService: StorageService,
    private utilityservice:UtilityService
  ) {

  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const req = this.getHeaders(request);
    const reqCloned = this.handleRequestBody(req);
    return next.handle(reqCloned);
  }

  getHeaders(request:any) {
    // let uid = environment.environment == 'sanity' ? 'Uowkjsdjssrme' : 'UserName';
    let url = request.url.toLowerCase();
    let req;
    // if (url.includes('CreateExternalToken') ) {
    //   let fcmaToken = localStorage.getItem('nysaHealthToken') || '';
    //   let fcmauthtoken = this.utilityservice.isUndefinedORNull(fcmaToken) ? '' : JSON.parse(fcmaToken);
    //   req = request.clone({
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json; charset=utf-8',
    //       'Authorization': "Bearer " + fcmauthtoken,
    //       'Cache-Control': 'no-cache',
    //     })
    //   });
    // }
    // else {
      req = request.clone({
        // 'Content-Type': "application/json",
        'Authorization': '',
        'Access-Control-Allow-Origin': '*',
        'X-Frame-Options': 'DENY',
      });

      let authToken = localStorage.getItem('authToken');
      // let userName = this.storageService.getAESDecryptedData(userEnums.UopqksmlN);
      if (authToken) {
        req = request.clone({
          setHeaders: {
            'Content-Type': "application/json",
            'Authorization': authToken ? authToken : '',
            'Access-Control-Allow-Methods': 'GET,POST',
            // [`${uid}`]: this.encryptDecryptService.finalRsaEncryption(userName),
            // 'AppName': this.encryptDecryptService.finalRsaEncryption('critishield'),
            // 'SessionReference': this.storageService.getAESDecryptedData(userEnums.SessionReference)
          }
        })
      }
    // }
    return req;
  }


  handleRequestBody(req: HttpRequest<any>) {
    let url;
    url = req.url.replace(environment.baseURL, "");
    if (req.method.toLowerCase() === 'get') {
      url.split("?")[0];
    }
    if (req.body) {
      let result = req.body instanceof FormData;
      if (result === false) {
        if (req.method.toLowerCase() === 'post') {
          let isReqArray = Array.isArray(req.body);
          let reqObjectType = typeof (req.body);
          if (isReqArray || reqObjectType === "object") {
            // if (url.includes('Intranet') || url.includes('plutus')) {
            //   req = req.clone(req.body);
            // }
            // else {
              req = req.clone({
                body: { "Data": this.encryptDecryptService.finalRsaEncryption(req.body) }
              })
            //  }
          } else {
            req = req.clone({
              body: { "Data": this.encryptDecryptService.finalRsaEncryption(JSON.parse(req.body)) }
            })
          }
        } else if (req.method.toLowerCase() === 'get') {
          let index = req.url.indexOf('?');
          if (index !== -1) {
            let domainName = req.url.substring(0, index);
            let parameters = req.url.substring(index + 1);
            if (parameters.length > 0) {
              req = req.clone({
                url: domainName + "?Data=" + this.encryptDecryptService.finalRsaEncryption(parameters),
              });
            } else {
              req = req.clone({
                url: domainName + "?Data=" + this.encryptDecryptService.finalRsaEncryption(parameters),
              });
            }
          }
        }
      }
    }
    return req;
  }

}
