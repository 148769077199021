import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilityService } from './utility.service';
import { PopupModal } from '../../layout/popup/popup.modal';
import { popupButton, popupButtonAction, popupDescriptionMsg, popupHeader, popupImgPath, popupType } from '../enums/popupEnums';
import { PopupComponent } from '../../layout/popup/popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
// back button pressed data
private backButton = new Subject<boolean>();
backButton$ = this.backButton.asObservable();
// back button logout data
private logout = new Subject<boolean>();
logout$ = this.logout.asObservable();

// delete data
private delete = new Subject<boolean>();
delete$ = this.delete.asObservable();
// refresh form data
private refreshForm = new Subject<boolean>();
refreshForm$ = this.refreshForm.asObservable();

// Close all dialog
private closeDialog = new BehaviorSubject<boolean>(false);
closeDialog$ = this.closeDialog.asObservable();

constructor(private dialog: MatDialog,
 private http: HttpClient,
 private utilityService: UtilityService) {
 }

  // Refresh form data
  emitRefreshFormData(value: boolean) {
   this.refreshForm.next(value);
 }
 // back button press
 emitBackButtonData(value: boolean) {
   this.backButton.next(value);
 }
 //logutdata
 emitLogoutData(value: boolean) {
   this.logout.next(value);
 }
 //delete data
 emitDeleteData(value: boolean) {
   this.delete.next(value);
 }
 // Call tthis service when application is redirected to other app or logged out
 closeAllDialog(data: boolean) {
   this.closeDialog.next(data);
 }
 // popup for generic structure
 openGenericPopup(popupModal: PopupModal) {

   let popupData: PopupModal = {
     popupType: popupModal.popupType,
     imgName: popupModal.imgName,
     header: popupModal.header,
     description: !this.utilityService.isEmptyOrNull(popupModal.description) ? popupModal.description : 'Something went wrong please try again later.',
     errorFlag: popupModal.errorFlag ? popupModal.errorFlag : false,
     data: popupModal.data ? popupModal.data : ' ',
     buttonLabel: popupModal.buttonLabel,
     buttonAction: popupModal.buttonAction
   };

   let className = popupType.confirmation ? ['my-custom-dialog', 'confirm-dialog'] : 'my-custom-dialog'
   this.dialog.open(PopupComponent, {
     panelClass: className,
     maxWidth: popupModal.popupType == popupType.confirmation ? '25vw' : '100vw',
     data: popupData,
     disableClose: popupModal.buttonAction == popupButtonAction.logout || popupModal.header == popupHeader.refreshData || popupModal.buttonAction == popupButtonAction.dashboard || popupModal.description == popupDescriptionMsg.emiNotApplicable ? true : false || popupModal.description == popupDescriptionMsg.cibilKYC ? true : false 
   }
   );
 }
// call below method to construct error popup message
fetchErrorPopupMsg(errorMsg: string, data?: string, errorFlag?: boolean): PopupModal {
 let popupData: PopupModal = {
   popupType: popupType.generic,
   imgName: popupImgPath.alertGif,
   header: popupHeader.error,
   description: errorMsg,
   errorFlag: errorFlag ? errorFlag : false,
   data: data ? data : '',
   buttonLabel: popupButton.ok,
   buttonAction: popupButtonAction.close,
 }
 return popupData
}
//Info popup
infoPopupMsg(errorMsg: string): PopupModal {
 let popupData: PopupModal = {
   popupType: popupType.generic,
   imgName: popupImgPath.successGif,
   header: popupHeader.success,
   description: errorMsg,
   buttonLabel: popupButton.ok,
   buttonAction: popupButtonAction.close
 }
 return popupData
}
}
