<!-- Travel Premium Summary -->
<!-- <div class="summaryMain pt-2" *ngIf="dialogData.popupType == popupType.travelPremiumSummary">
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12 closediv">
        <h1 class="summaryHead">Summary</h1>
        <div class="closebtn" (click)="close()">
          <img src="../../../assets/images/cross.png" alt="close" style="width: 12px; height: 12px;">
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable">
          <caption class="hidden-caption">Details</caption>
          <th colspan="2" class="subheadSum">Premium for {{travelPremiumDetailsData.numberOfTraveller}} Travellers</th>
          <td class="hidden-caption">Premium Details</td>
          <tr>
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont">Premium</td>
            <td>₹ {{ travelPremiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(travelPremiumDetailsData.totalPremium):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer bgSummary">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable ">
          <caption class="hidden-caption">Details</caption>
          <th class="hidden-caption">Premium Details</th>
          <td class="hidden-caption">Plan</td>
          <tr>
            <td colspan="2">Base premium</td>
            <td>₹ {{travelPremiumDetailsData?.basicPremium ?
              utilityService.convertStringtoCurrency(travelPremiumDetailsData?.basicPremium): ''}}</td>
          </tr>
          <tr>
            Commeneted as it was giving error
            <td colspan="2">{{gst}}</td>
            <td> ₹ {{travelPremiumDetailsData?.totalTax ?
              utilityService.convertStringtoCurrency(travelPremiumDetailsData?.totalTax):
              ''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer sharePd" *ngIf="finalAddonList?.length>0">
    <div class=" row">
      <div class="col-sm-12">
        <div class="addonsmainTable">
          <table class="addonsTable">
            <caption class="hidden-caption">Details</caption>
            <td class="hidden-caption">Premium Details</td>
            <thead>
              <tr>
                <th>Add ons</th>
                <th class="text-center">Sum Insured<th>
                <th class="text-center">Adult 2</th>
                <th class="text-center">All Insured</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of finalAddonList">
                <td>{{item.coverText}}</td>
                <td>{{item.coverText}}</td>
                <td>
                  <img src="../../../../assets/images/miniTick.svg" alt=""
                    *ngIf="item[item.coverCode]">
                </td>
                <td>
                  <img src="../../../../assets/images/miniTick.svg" alt=""
                    *ngIf="item[item.coverCode] && !item.isAddonMemberBasis">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container summaryContainer shareDiv">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable summaryFooter">
          <caption class="hidden-caption">Details</caption>
          <th class="hidden-caption">Premium Details</th>
          <td class="hidden-caption">Total Premium</td>
          <tr>
            <td style="width: 60% !important;">Total premium</td>
            <td *ngIf="this.productType?.toUpperCase() == 'HEALTH'">₹ {{ premiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData.totalPremium):''}}</td>
            <td *ngIf="this.productType?.toUpperCase() == 'TRAVEL'">₹ {{ travelPremiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(travelPremiumDetailsData.totalPremium):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div> -->

<!-- ZeroTat Premium Summary -->
<!-- <div class="summaryMain pt-2" *ngIf="dialogData.popupType == popupType.zeroTatpremiumSummary">
  <div class="summaryMain p-0">
    <div class="container summaryContainer">
      <div class="row">
        <div class="col-sm-12 closediv">
          <h1 class="summaryHead">Summary</h1>
          <div class="closebtn" (click)="close()">X</div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="container summaryContainer">
      <div class="row">
        <div class="col-sm-12">
          <h3 class="subheadSum">{{ this.healthFormData.quoteFormData.adultDetails.length}} Adult</h3>
          <div *ngIf="(healthFormData.quoteFormData.subProductType == 38)">
            <h3 class="subheadSum">{{ this.healthFormData.quoteFormData.childDetails.length}} Child</h3>
          </div>
          <table class="viewsummaryTable">
            <tr>
              <td [width]="20">
                <span class="tickIcon">
                  <img src="../../../../assets/images/miniTick.svg" alt="">
                </span>
              </td>
              <td class="miniFont">Cover</td>
              <td>₹{{this.healthFormData.premiumFormData.InstallmentNetPremium }} </td>
            </tr>
            <tr>
              <td [width]="20">
                <span class="tickIcon">
                  <img src="../../../../assets/images/miniTick.svg" alt="">
                </span>
              </td>
              <td class="miniFont">Premium</td>
              <td>₹{{this.healthFormData.premiumFormData.totalPremium }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="container summaryContainer bgSummary">
      <div class="row">
        <div class="col-sm-12">
          <table class="viewsummaryTable ">
            <tr>
              <td colspan="2">Base premium</td>
              <td>₹{{this.healthFormData.premiumFormData.basicPremium }}</td>
            </tr>
            <tr>
              <td colspan="2">GST@18%</td>
              <td>₹{{this.healthFormData.premiumFormData.totalTax }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div class="container summaryContainer bgSummary" *ngIf="(healthFormData.quoteFormData.subProductType == 23)">
      <div class="row">
        <div class="col-sm-12">
          <table class="viewsummaryTable ">
            <tr>
              <td colspan="2">
                <span>Covers: </span>
                <span class="filteredCover">{{selectedButton}}</span>
              </td>
              <td>
                <div class="dropdown">
                  <button class="dropdown-toggle customDropdown" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div class="toggIcon">
                      <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
                        <g fill="#fff" stroke="#d1cdcd" class="filterfillicon" stroke-width="1">
                          <rect width="35" height="35" rx="8" stroke="none" />
                          <rect x="0.5" y="0.5" width="34" height="34" rx="7.5" fill="none" />
                        </g>
                        <path class="filterfillicon"
                          d="M9.907,4h.186a4.13,4.13,0,0,1,1.054.085,2.5,2.5,0,0,1,1.768,1.768A4.131,4.131,0,0,1,13,6.907c0,.031,0,.062,0,.093h8a1,1,0,1,1,0,2H13c0,.031,0,.062,0,.093a4.132,4.132,0,0,1-.085,1.054,2.5,2.5,0,0,1-1.768,1.768A4.131,4.131,0,0,1,10.093,12H9.907a4.131,4.131,0,0,1-1.054-.085,2.5,2.5,0,0,1-1.768-1.768A4.131,4.131,0,0,1,7,9.093Q7,9.047,7,9H5A1,1,0,1,1,5,7H7q0-.047,0-.093a4.13,4.13,0,0,1,.085-1.054A2.5,2.5,0,0,1,8.853,4.085,4.131,4.131,0,0,1,9.907,4ZM9.5,6.005a.771.771,0,0,0-.128.012.5.5,0,0,0-.354.354.834.834,0,0,0-.012.128C9,6.611,9,6.758,9,7V9c0,.242,0,.389.005.5a.768.768,0,0,0,.012.128.5.5,0,0,0,.354.354.77.77,0,0,0,.128.012c.112.005.26.005.5.005s.389,0,.5-.005a.772.772,0,0,0,.128-.012.5.5,0,0,0,.354-.354.771.771,0,0,0,.012-.128C11,9.389,11,9.242,11,9V7c0-.242,0-.389-.005-.5a.771.771,0,0,0-.012-.128M9.5,6.005C9.611,6,9.758,6,10,6ZM10,6c.242,0,.389,0,.5.005Zm.5.005a.833.833,0,0,1,.128.012Zm.128.012h0ZM15.907,12h.186a4.131,4.131,0,0,1,1.054.085,2.5,2.5,0,0,1,1.768,1.768A4.131,4.131,0,0,1,19,14.907c0,.031,0,.062,0,.093h2a1,1,0,0,1,0,2H19c0,.031,0,.062,0,.093a4.132,4.132,0,0,1-.085,1.054,2.5,2.5,0,0,1-1.768,1.768A4.131,4.131,0,0,1,16.093,20h-.186a4.131,4.131,0,0,1-1.054-.085,2.5,2.5,0,0,1-1.768-1.768A4.132,4.132,0,0,1,13,17.093c0-.031,0-.062,0-.093H5a1,1,0,0,1,0-2h8c0-.031,0-.062,0-.093a4.131,4.131,0,0,1,.085-1.054,2.5,2.5,0,0,1,1.768-1.768A4.131,4.131,0,0,1,15.907,12ZM15.5,14.005a.773.773,0,0,0-.128.012.5.5,0,0,0-.354.354.773.773,0,0,0-.012.128c-.005.112-.005.26-.005.5v2c0,.242,0,.389.005.5a.767.767,0,0,0,.012.128.5.5,0,0,0,.354.354.776.776,0,0,0,.128.012c.112.005.26.005.5.005s.389,0,.5-.005a.776.776,0,0,0,.128-.012.5.5,0,0,0,.354-.354.768.768,0,0,0,.012-.128c.005-.112.005-.26.005-.5V15c0-.241,0-.389-.005-.5a.773.773,0,0,0-.012-.128.5.5,0,0,0-.354-.354.773.773,0,0,0-.128-.012C16.389,14,16.242,14,16,14S15.611,14,15.5,14.005Z"
                          transform="translate(5 6)" fill="#1e1f20" fill-rule="evenodd" />
                      </svg>
                    </div>
                  </button>
                  <div class="dropdown-menu customDropItems" aria-labelledby="dropdownMenuButton">
                    <button class="dropdown-item cs_dropdown-item" *ngFor="let item of filterlist"
                      (click)="filter_Coverlist(item)">
                      <div class="custom-radio">
                        <input type="radio" name="radiogroup" [value]="item" [(ngModel)]="selectedButton" />
                        <label>{{item}}</label>
                      </div>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="container summaryContainer sharePd">
      <div class="row">
        <div class="col-sm-12" *ngIf="(healthFormData.quoteFormData.subProductType == 23)">
          <div class="addonsmainTable">
            <table class="addonsTable">
              <thead>
                <tr>
                  <th>Add ons</th>
                  <th *ngFor="let data of CoverAddonList; let i = index">Adult {{i+1}}</th>
                  <th>All Insured</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of quoteSummaryData; let i = index">
                  <td>{{data.CoverName}}</td>
                  <td *ngFor="let istrue of data.AdultAtatus;">
                    <span *ngIf="istrue">
                      <img src="../../../../assets/images/miniTick.svg" alt="">
                    </span>
                  </td>
                  <td>
                    <span *ngIf="data.allInsured">
                      <img src="../../../../assets/images/miniTick.svg" alt="">
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="(healthFormData.quoteFormData.subProductType == 38) && (critishieldCoversList.length > 0)">
          <div class="container summaryContainer bgSummary">
            <div class="row">
              <div class="col-sm-12">
                <table class="viewsummaryTable ">
                  <tr>
                    <td colspan="2">
                      <span>Covers: </span>
                      <span class="filteredCover"> {{critishieldSelectedCover}}</span>
                    </td>
                  </tr>

                </table>

              </div>
            </div>
          </div>
          <div class="container summaryContainer sharePd">
            <div class="row">

              <div class="col-sm-12">
                <div class="addonsmainTable">
                  <table class="addonsTable">
                    <thead>
                      <tr>
                        <th>Add ons</th>
                        <th *ngFor="let data of critishieldCoversList; let i = index">{{data['memberName']}}</th>
                        <th>Adult 2</th>
                        <th>All Insured</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let data of critishieldCoversList; let i = index">
                      <tr *ngIf="i < 1">
                        <td>{{data['coverName']}}</td>
                        <td *ngFor=" let adult of healthFormData.quoteFormData['memberCoverDetails']; let a = index">
                          <span *ngIf="data.AdultStatusFlag">
                            <img src="../../../../assets/images/miniTick.svg" alt="">
                          </span>
                        </td>
                        <td
                          *ngFor=" let child of healthFormData.quoteFormData['memberCoverDetailsChild']; let c = index">
                          <span *ngIf="data.ChildStatusFlag">
                            <img src="../../../../assets/images/miniTick.svg" alt="">
                          </span>
                        </td>
                        <td>
                          <span *ngIf="data.AllInsuredFlag">
                            <img src="../../../../assets/images/miniTick.svg" alt="">
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>


                </div>
              </div>
            </div>
          </div>
        </div>

        <mat-divider></mat-divider>
        <div class="container summaryContainer shareDiv">
          <div class="row">
            <div class="col-sm-12">
              <table class="viewsummaryTable summaryFooter">
                <tr>
                  <td style="width: 60% !important;">Total premium</td>
                  <td>₹{{this.healthFormData.premiumFormData.totalPremium }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Premium Summary -->
<!-- <div class="summaryMain pt-2" *ngIf="dialogData.popupType == popupType.premiumSummary">
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12 closediv">
        <h1 class="summaryHead">Summary</h1>
        <div class="closebtn" (click)="close()">
          <img src="../../../assets/images/cross.png" alt="close" style="width: 12px; height: 12px;">
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable">
          <caption class="hidden-caption">Details</caption>
          <th colspan="2" class="subheadSum">{{premiumDetailsData.adult}} Adult, {{premiumDetailsData.child}} Child</th>
          <td class="hidden-caption">Premium Details</td>
          <tr class="d-lg-none">
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont text-left">Zone {{ premiumDetailsData?.zone}}</td>
            <td> </td>
          </tr>
          <tr *ngIf="premiumDetailsData.coverRange.length>0">
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont">Cover</td>
            <td>{{premiumDetailsData.coverRange.includes('Unlimited') ? 'Unlimited' :
              utilityService.easyAmountReader(premiumDetailsData.coverRange)}}</td>
          </tr>
          <tr>
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont">Premium</td>
            <td>₹ {{ premiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData.totalPremium):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer bgSummary">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable ">
          <caption class="hidden-caption">Details</caption>
          <th class="hidden-caption">Premium Details</th>
          <td class="hidden-caption">Plan</td>
          <tr>
            <td colspan="2">Base premium</td>
            <td>₹ {{premiumDetailsData?.basicPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData?.basicPremium): ''}}</td>
          </tr>
          <tr *ngIf="this.premiumDetailsData.installmentApplicable">
            <td colspan="2">EMI Plan
            </td>
            <td>
              {{'₹'+utilityService.convertStringtoCurrency(premiumDetailsData?.installmentTotalPremium)+'/'+this.premiumDetailsData.installmentFrequency}}
            </td>
          </tr>
          <tr>
            Commeneted as it was giving error
            <td colspan="2">{{gst}}</td>
            <td> ₹ {{premiumDetailsData?.totalTax ?
              utilityService.convertStringtoCurrency(premiumDetailsData?.totalTax):
              ''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer sharePd" *ngIf="finalAddonList.length>0">
    <div class=" row">
      <div class="col-sm-12">
        <div class="addonsmainTable">
          <table class="addonsTable">
            <caption class="hidden-caption">Details</caption>
            <td class="hidden-caption">Premium Details</td>
            <thead>
              <tr>
                <th>Add ons</th>
                <th class="text-center">Adult 1</th>
                <th *ngIf="this.premiumDetailsData.adult > 1" class="text-center">Adult 2</th>
                <th class="text-center">All Insured</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of finalAddonList">
                <td>{{item.coverText}}</td>
                <td>
                  <img src="../../../../assets/images/miniTick.svg" alt=""
                    *ngIf="item['AddOn'+item.coverCode+'Member1']">
                </td>
                <td *ngIf="this.premiumDetailsData.adult > 1">
                  <img src="../../../../assets/images/miniTick.svg" alt=""
                    *ngIf="item['AddOn'+item.coverCode+'Member2']">
                </td>
                <td>
                  <img src="../../../../assets/images/miniTick.svg" alt=""
                    *ngIf="item[item.coverCode] && !item.isAddonMemberBasis">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container summaryContainer shareDiv">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable summaryFooter">
          <caption class="hidden-caption">Details</caption>
          <th class="hidden-caption">Premium Details</th>
          <td class="hidden-caption">Total Premium</td>
          <tr>
            <td style="width: 60% !important;">Total premium</td>
            <td>₹ {{ premiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData.totalPremium):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div> -->

<!-- HAP refilling Summary -->
<!-- <div class="summaryMain" *ngIf="dialogData.popupType == popupType.hapRefillingSummary">
  <div class="container summaryContainer viewSummHeading">
    <div class="row">
      <div class="col-sm-12 closediv">
        <h1 class="summaryHead">Summary</h1>
        <div class="closebtn" (click)="close()">
          <img src="../../../assets/images/cross.png" alt="close" style="width: 12px; height: 12px;">
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable">
          <caption class="hidden-caption">Details</caption>
          <th colspan="2" class="subheadSum">{{premiumDetailsData.adult}} Adult, {{premiumDetailsData.child}} Child</th>
          <td class="hidden-caption">Premium Details</td>
          <tr>
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont">Zone</td>
            <td *ngIf = "!this.healthFormData.quoteFormData.reviseZone">{{ this.healthFormData.quoteFormData.zone}}</td>
            <td *ngIf = "this.healthFormData.quoteFormData.reviseZone">{{ this.healthFormData.quoteFormData.reviseZone}}</td>
          </tr>
          <tr *ngIf="premiumDetailsData.coverRange.length>0">
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont">Cover</td>
            <td>{{premiumDetailsData.coverRange.includes('Unlimited') ? 'Unlimited' :
              utilityService.easyAmountReader(premiumDetailsData.coverRange)}}</td>
          </tr>
          <tr>
            <td>
              <span class="tickIcon">
                <img src="../../../../assets/images/miniTick.svg" alt="">
              </span>
            </td>
            <td class="miniFont">Premium</td>
            <td>₹ {{ premiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData.totalPremium):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer bgSummary">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable ">
          <caption class="hidden-caption">Details</caption>
          <th class="hidden-caption">Premium Details</th>
          <td class="hidden-caption">Plan</td>
          <tr>
            <td colspan="2">Base premium</td>
            <td>₹ {{premiumDetailsData?.basicPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData?.basicPremium): ''}}</td>
          </tr>
          <tr *ngIf="this.premiumDetailsData.installmentApplicable">
            <td colspan="2">EMI Plan
            </td>
            <td>
              {{'₹'+utilityService.convertStringtoCurrency(premiumDetailsData?.installmentTotalPremium)+'/'+this.premiumDetailsData.installmentFrequency}}
            </td>
          </tr>
          <tr>
            Commeneted as it was giving error
            <td colspan="2">{{gst}}</td>
            <td> ₹ {{premiumDetailsData?.totalTax ?
              utilityService.convertStringtoCurrency(premiumDetailsData?.totalTax):
              ''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer sharePd">
    <div class=" row">
      <div class="col-sm-12">
        <div class="addonsmainTable">
          <table class="addonsTable">
            <caption class="hidden-caption">Details</caption>
            <td class="hidden-caption">Premium Details</td>
            <thead>
              <tr>
                <th>Add ons</th>
                <th class="text-center" *ngFor="let name of hapRefillingAddons.memberArray">{{name}}</th>
                <th class="text-center">All Insured</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of hapRefillingAddons.summaryAddons; let i = index">
                <td>{{item.coverText}}</td>
                <td *ngFor="let _ of hapRefillingAddons.memberArray; let k = index">
                  <img src="../../../../assets/images/miniTick.svg" alt="" *ngIf="compareTick(k+1,i)">
                </td>
                <td>
                  <img src="../../../../assets/images/miniTick.svg" alt="" *ngIf="item.isAllInsuredSelected">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="container refillingDiv">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable summaryFooter">
          <caption class="hidden-caption">Details</caption>
          <th class="hidden-caption">Premium Details</th>
          <td class="hidden-caption">Total Premium</td>
          <tr>
            <td style="width: 60% !important;">Total premium</td>
            <td>₹ {{ premiumDetailsData?.totalPremium ?
              utilityService.convertStringtoCurrency(premiumDetailsData.totalPremium):''}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div> -->

<!-- renewal summary -->
<!-- <div class="summaryMain pt-2" *ngIf="dialogData.popupType == popupType.renewalSummary">
  <div class="container summaryContainer">
    <div class="row">
      <div class="col-sm-12 closediv">
        <h1 class="summaryHead">Summary</h1>
        <div class="closebtn" (click)="close()">
          <img src="../../../assets/images/cross.png" alt="close" style="width: 12px; height: 12px;">
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer bgSummary">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable ">
          <tr>
            <td colspan="2">Premium Payable</td>
            <td>
              ₹{{this.footerData?.TotalPremium?utilityService.convertStringtoCurrency(this.footerData?.TotalPremium):0}}
            </td>
          </tr>
          <tr>
            <td colspan="2">Base premium</td>
            <td>
              ₹{{this.footerData?.BasicPremium?utilityService.convertStringtoCurrency(this.footerData?.BasicPremium):0}}
            </td>
          </tr>
          <tr *ngIf="this.renewalDataStorage.ProductType == '42' && this.renewalDataStorage.ZoneRefilingflag == 'Yes' ">
            <td colspan="2">Optional Covers</td>
            <td>
              ₹{{this.footerData?.OptionalCovers?utilityService.convertStringtoCurrency(this.footerData?.OptionalCovers):0}}
            </td>
          </tr>
          <tr>
            <td colspan="2">Sublimit</td>
            <td>₹0</td>
          </tr>
          <tr>
            <td colspan="2">GST</td>
            <td>₹{{this.footerData?.TotalTax?utilityService.convertStringtoCurrency(this.footerData?.TotalTax):0}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="container summaryContainer shareDiv">
    <div class="row">
      <div class="col-sm-12">
        <table class="viewsummaryTable summaryFooter">
          <td class="hidden-caption">Total Renewal Payable Premium</td>
          <tr>
            <td style="width: 60% !important;">Total Premium</td>
            <td>
              ₹{{this.footerData?.TotalPremium?utilityService.convertStringtoCurrency(this.footerData?.TotalPremium):0}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div> -->

<!-- Generic popup -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.generic">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="row">
        <div class="col-sm-12">
          <div class="popupBox">
            <div class="successful" *ngIf="dialogData.imgName != popupImgPath.alertGif">
              <img [src]="dialogData.imgName" alt="">
            </div>
            <h1 *ngIf="dialogData.header != popupHeader.error">{{dialogData.header}}</h1>
            <div style="overflow: auto !important;">
              <div class="notice" *ngIf="dialogData.description">
                <p
                  [ngClass]="{'pTag': dialogData.imgName == popupImgPath.alertGif && dialogData.header == popupHeader.error}">
                  {{dialogData.description}}</p>
              </div>
            </div>
            <div class="text-center logHeight" *ngIf="dialogData.errorFlag">
              <div class="logInput">
                <input class="logBorder" [value]="dialogData.data" readonly disabled />
                <div class="logCopyCode" [cdkCopyToClipboard]="dialogData.data" (click)="toggleCopyFunction()"><img
                    src="../../../assets/images/LogCopy.svg" alt="" class="logImg">
                </div>
              </div>
              <div *ngIf="toggleCopy" class="mt-1">
                <span class="logSuccessful">Successfully Copied!</span>
              </div>
            </div>
            <div class="btnmargin">
              <button class="secondarybtn_2 popupbtn" (click)="close()">{{dialogData.buttonLabel}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Confirmation Popup -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.confirmation">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="row">
        <div class="col-sm-12">
          <div class="popupBox">
            <div class="successful" *ngIf="dialogData.imgName">
              <img [src]="dialogData.imgName" alt="">
            </div>
            <h2 class="pedHeader" style="text-align: center;" *ngIf="dialogData.header">{{dialogData.header}}</h2>
            <span style="text-align: center;" *ngIf="dialogData.description">{{dialogData.description}}
            </span>
          </div>
        </div>
        <div class="col-sm-12 text-center mb-3">
          <button class="back-button btnInsured mr-2" (click)="cancelClicked()">Cancel</button>
          <button class="secondarybtn_2 secbtnInsured" (click)="proceedClicked()">Okay</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- upload document Popup -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.uploadDox">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="row">
        <div class="col-sm-12">
          <div class="popupBox">
            <div class="successful" *ngIf="dialogData.imgName">
              <img [src]="dialogData.imgName" alt="">
            </div>
            <h2 class="pedHeader" style="text-align: center;" *ngIf="dialogData.header">{{dialogData.header}}</h2>
            <span style="text-align: center;" *ngIf="dialogData.description">{{dialogData.description}}
            </span>
          </div>
        </div>
        <div class="col-sm-12 text-center mb-3">
          <button class="back-button btnInsured mr-2" (click)="cancelClicked()">Yes</button>
          <button class="secondarybtn_2 secbtnInsured" (click)="close()">No</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Payment Popup -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.payment">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="sticky-top custom-header row">
        <h5>Choose Payment Method</h5>
        <div class="custom-close" (click)="close()">
          <img class="custom-cross" src="../../../../../assets/images/cross.png" alt="X">
        </div>
      </div>
      <div class="row pt-2" *ngIf="showPayLater">
        <div class="col-sm-12">
          <div class="popupBox pBox">
            <button class="secondary-button payment_overBtn paymentBtnsWidth">
              <span><button mat-raised-button class="button_payment paymentBtnsWidth" type="button"
                  (click)="handleEvent('PayLater')">Pay Later</button></span>
            </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="showPayNow">
        <div class="col-sm-12">
          <div class="popupBox pBox">
            <button class="secondary-button payment_overBtn paymentBtnsWidth">
              <span><button mat-raised-button class="button_payment paymentBtnsWidth" type="button"
                  (click)="handleEvent('PayNow')">Pay Now</button></span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="popupBox pBox">
            <button class="primary-button-new primBtnLink paymentBtnsWidth">
              <span style="letter-spacing: .0892857143em;"><button mat-raised-button
                  class="primary-button paymentBtnsWidth" type="button" (click)="handleEvent('PaymentLink')">Payment
                  Link</button></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- Cheque Status Popup -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.chequeStatus">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="sticky-top custom-header row">
        <h5>Cheque Status</h5>
        <div class="custom-close">
          <img class="custom-cross" (click)="close()" src="../../../../../assets/images/cross.png" alt="X">
        </div>
      </div>

      <div class="stepper-wrapper">
        <div class="stepper-item completed">
          <div class="step-counter"></div>
          <div class="step-name">Submitted</div>
        </div>
        <ng-container *ngIf="dialogData.description !== popupChequeStatus.cancelled">
          <div class="stepper-item"
            [ngClass]="dialogData.description === popupChequeStatus.accepted || dialogData.description === popupChequeStatus.confirmed ? 'completed': ''">
            <div class="step-counter"></div>
            <div class="step-name">Accepted</div>
          </div>
          <div class="stepper-item" [ngClass]="dialogData.description === popupChequeStatus.confirmed? 'completed':''">
            <div class="step-counter"></div>
            <div class="step-name">Confirmed</div>
          </div>
        </ng-container>

        <ng-container *ngIf="dialogData.description === popupChequeStatus.cancelled">
          <div class="stepper-item" [ngClass]="dialogData.description === popupChequeStatus.cancelled?'completed':''">
            <div class="step-counter"></div>
            <div class="step-name">Cancelled</div>
          </div>
        </ng-container>

      </div>

      <div class="cheque-number">
        <span>Cheque number : {{dialogData.data.ChequeNumber}}</span>
      </div>
    </div>
  </div>
</div> -->

<!-- Risk Category Popup -->
<!-- <div *ngIf="dialogData.popupType == popupType.riskCategory">
  <mat-tab-group mat-stretch-tabs="true">
    <mat-tab *ngFor="let tabData of dialogData.data">
      <ng-template mat-tab-label>
        {{'Category ' + tabData.CategoryId}}
      </ng-template>
      <div class="fieldsdiv categorygroup">
        <div class="row">
          <div class="col-sm-12 form-group">
            <ul class="categories">
              <li>
                <table class="categoryTable">
                  <tr *ngFor="let category of tabData.Category">
                    <td>
                      <span class="catRight"><img src="../../../../assets/images/miniTick.svg" alt=""
                          class="add_tick"></span>
                    </td>
                    <td>
                      <span>{{category}}</span>
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div> -->

<!-- email input pop-up -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.email">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="row">
        <div class="col-sm-12">
          <div class="popupBox">
            <h2 class="pedHeader" style="text-align: center;">Send Email</h2>
            <span style="text-align: center;">To
            </span>
            <input type="text" #inputField matInput class="input-group__input health planselect"
              placeholder="Enter Email" type="text">
          </div>
        </div>
        <div class="col-sm-12 text-center mb-3">
          <button class="back-button btnInsured mr-2" (click)="cancelClicked()">Cancel</button>
          <button class="secondarybtn_2 secbtnInsured" (click)="emitEmail(inputField.value)">Okay</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- sales genie popup -->
<!-- <div class="container" *ngIf="dialogData.popupType == popupType.salesGenie">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="sticky-top custom-header row margin-bot-10">
        <h5>{{dialogData.header}}</h5>
        <div class="custom-close" (click)="close()">
          <img class="custom-cross" src="../../../../../assets/images/cross.png" alt="X">
        </div>
      </div>

      <ng-container *ngIf="dialogData.header === 'Sales Genie - CAS'">
        <customer-card [token]="dialogData.data"></customer-card>
      </ng-container>
      <ng-container *ngIf="dialogData.header === 'Renewal Score'">
        <policy-card [token]="dialogData.data.token" [policyNumber]="dialogData.data.policyNumber"></policy-card>
      </ng-container>

    </div>
  </div>
</div>

<div class="container" *ngIf="dialogData.popupType == popupType.declarations">
  <div class="innerbox plandetailPanel">
    <div class="fieldsdiv">
      <div class="sticky-top custom-header row margin-bot-10">
        <h5>{{dialogData.header}}</h5>
        <div class="custom-close" (click)="close()">
          <img class="custom-cross" src="../../../../../assets/images/cross.png" alt="X">
        </div>
      </div>

      <ng-container *ngIf="dialogData.header === 'Agent Declaration'">
        <p class="text-justify">
          I, {{dialogData.data?.agentName}} in my capacity as an Insurance Advisor/ Specified Person of the Corporate
          Agent/Authorized employee of the Broker/Relationship Officer, do hereby declare that I have explained all the
          contents of this Proposal Form, including the nature of the questions contained in this Proposal Form to the
          Proposer including statement(s), information and response(s) submitted by him/her in this Proposal Form to
          questions contained herein or any details sought herein will form the basis of the Contract of Insurance
          between the Company and the Proposer, if this Proposal is accepted by the Company for issuance of the Policy.
          I have further explained that if any untrue statement(s)/ information/response(s) is/are contained in this
          Proposal Form/including addendum(s), affidavits, statements, submissions, furnished/to be furnished, the
          Company shall have the right to cancel the policy at its discretion. Further, this declaration does not
          confirm issuance of policy or assumption of risk thereof.
        </p>
      </ng-container>

      <ng-container *ngIf="dialogData.header === 'Agent\'s Confidentiality Report'">
        <ng-container *ngIf="dialogData.data.productType === 38">
          <p class="text-justify">
            I, {{dialogData.data?.agentName}} in my capacity as an Insurance Advisor/ Specified Person of the Corporate
            Agent/Authorized
            employee of the Broker/Relationship Officer, do hereby declare that I have explained all the contents of
            this Proposal Form, including the nature of the questions contained in this Proposal Form to the Proposer
            including statement(s), information and response(s) submitted by him/her in this Proposal Form to
            questions contained herein or any details sought herein will form the basis of the Contract of Insurance
            between the Company and the Proposer, if this Proposal is accepted by the Company for issuance of the
            Policy. I have further explained that if any untrue statement(s)/ information/response(s) is/are contained
            in this Proposal Form/including addendum(s), affidavits, statements, submissions, furnished/to be
            furnished, the Company shall have the right to cancel the policy at its discretion. Further, this
            declaration does not confirm issuance of policy or assumption of risk thereof.
          </p>
        </ng-container>
        <ng-container *ngIf="dialogData.data.productType !== 38">
          <p class="text-justify">
            I, {{dialogData.data?.agentName}} in my capacity as an Insurance Advisor/ Specified Person of the Corporate
            Agent, Insurance Sales
            Persons of Insurance Marketing Firm / Broker Qualified Person, Rural Authorized Person (RAP) and Village
            Level
            Entrepreneur – Ins (VLE-Ins) of Common Public Service Centre do hereby declare that I have explained all the
            contents of this Proposal Form, including the nature of the questions contained in this Proposal Form to the
            Proposer including statement(s), information and response(s) submitted by him/her in this Proposal Form to
            questions contained herein or any details sought herein will form the basis of the Contract of Insurance
            between the Company and the Proposer, if this Proposal is accepted by the Company for issuance of the
            Policy.
            I have further explained that if any untrue statement(s)/ information/response(s) is/are contained in this
            Proposal Form/including addendum(s), affidavits, statements, submissions, furnished/to be furnished, the
            Company shall have the right to cancel the policy at its discretion. Further, this declaration does not
            confirm issuance of policy or assumption of risk thereof.
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="dialogData.header === 'Declaration'">
        <span class="text-justify">
          <ol>
            <li>
              I hereby declare, on my behalf that the above statements, answers and/or particulars given by me are true
              and complete in all respects to the best of my knowledge and that I am authorised to propose on behalf of
              these other persons.
            </li>

            <li>
              I understand that the information provided by me will form the basis of the insurance policy, is subject
              to
              the Board approved underwriting policy of the insurere and that the policy will come into force only after
              full payment of premium chargeable.
            </li>
            <li>
              I further declare that I will notify in writing any change occuring in occupation or general health of the
              life to be insured/proposer after the proposal has been submitted but before communication of the risk
              acceptance by the comapny.
            </li>

            <li>I declare that i consent to the company seeking medical information from any doctor or hospital
              who/which
              at any time has attended on the person to be insured/proposer or from any past or present employer
              concerning
              anything which affects the physical or mental health of the person to be insured/proposer and seeking
              information from any insurer to whom an application for the purpose of underwrting the proposal and/or
              claim
              settlement.
            </li>

            <li>I authorize the comapny to share information pertaining to my proposal including the medical records of
              insured/proposer fort he sole purpose of underwrting the proposal and/or claims settlement and with any
              Govermental and/or regulatory authority.
            </li>

            <li>I have been provided with the detailed terms of the policy. Ihave read, understood and am aware of the
              detailed terms of policy.
            </li>

            <li>I hereby give consent for recieving proposal form and policy copy in electronic form only. The provision
              for physical copy of the proposal form would be as per the revelant IRDA guidelines.
            </li>

            <li>I hereby confirm that I have insurable interest in the policy and premium is not borne by any third
              party
              entity or person.
            </li>
          </ol>
        </span>
      </ng-container>
      <ng-container *ngIf="dialogData.header === 'Terms & Conditions'">
        <span class="text-justify">
          <ol>
            <li>
              Intial Waiting Period of 30 days for all Illnesses (except Hospitalization due to Accidental Injury)
            </li>
            <li>
              Specific Waiting Period of first two years for specific illenesses and treatments (mentioned in the policy
              wording)
            </li>
            <li>
              Pre-existing Diseases declared and accepted by Us will be covered after completion of waiting Period of
              two
              years
            </li>
            <li>
              For detailed Terms and Condition, kindly refer to the policy wordings
            </li>
          </ol>
        </span>
      </ng-container>

    </div>
  </div>
</div> -->

<!-- search popup -->
<div *ngIf="dialogData.popupType == popupType.search" class="mat-typography">
    <!-- Input fields card start -->
    <div class="searchParamfilter">
      <h3 class="mb-0">Search By</h3>
      <div class="custom-close" (click)="closeSearchBottomSheet('close')">
        <img class="custom-cross" src="https://cldilmobilapp01.insurancearticlez.com/ipartnermsite/customComponentProj/assets/images/cross.png" alt="X">
      </div>
    </div>
    <div class="paddingDiv">
      <div class="container filtercontainer">
        <div *ngFor="let data of dataSearch.controls; let i = index">
  
          <div *ngIf="data.type == 'input'">
            <mat-label>{{data.label}}</mat-label>
            <input matInput type="text" class="input-group__input" [value]="data.value" [readonly]="data.readonly">
          </div>
  
          <div *ngIf="data.type == 'dropdown'">
            <mat-label class="input-group__label">{{data.label}}</mat-label>
            <mat-select class="input-group__input cstm_select" (click)="handleMatSelectClick()"
              (blur)="removeMatSelectScrollListener()" (selectionChange)="applyDropdownValue($event,i)"
              [(value)]="data.value">
              <ng-container *ngFor="let item of data.list; let last = last">
                <mat-option [value]="item">
                  {{item | titlecase}}
                </mat-option>
                <!-- <mat-divider *ngIf="!last"></mat-divider> -->
              </ng-container>
            </mat-select>
          </div>
  
          <div class="form-group" *ngIf="data.type == 'rangepicker'">
            <mat-label class="input-group__label">{{data.label}}</mat-label>
            <mat-date-range-input class="input-group__input dob" [rangePicker]="picker" 
            (click)="picker.open()"
              [min]="this.dataSearch.dates.minDate" [max]="this.dataSearch.dates.maxDate">
              <input matStartDate placeholder="Start date" [value]="data.startDate"
                (dateChange)="applyDate($event.value, i, 'start')">
              <input matEndDate placeholder="End date" [value]="data.endDate"
                (dateChange)="applyDate($event.value, i, 'end')">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker touchUi></mat-date-range-picker>
          </div>
  
        </div>
  
        <div class="row">
          <div class="col-sm-12 text-center">
            <button class="search-button" (click)="closeSearchBottomSheet('search')">
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Input fields card end -->
  </div>
  
  <!-- filter popup -->
  <div *ngIf="dialogData.popupType == popupType.filter" class="mat-typography">
    <!-- Input fields card start -->
    <div class="searchParamfilter">
      <h3 class="mb-0">Filter By</h3>
      <div class="custom-close" (click)="closeFilterBottomSheet('close')">
        <img class="custom-cross" src="https://cldilmobilapp01.insurancearticlez.com/ipartnermsite/customComponentProj/assets/images/cross.png" alt="X">
      </div>
    </div>
    <div class="paddingDiv">
      <div class="container filtercontainer">
        <div class="row" *ngFor="let value of dataFilter; let i = index">
          <h3 class="filtersheetheading">{{value.label}}</h3>
          <div class="col-sm-12">
            <ul class="filterTypebtmSheet">
              <li *ngFor="let item of value.list">
                <mat-checkbox [checked]="value.filter.includes(item.toLowerCase())"
                  (change)="applyFilter($event, item, i)">
                  {{ utilityService.truncateAfterWhiteSpace(item) }}
                </mat-checkbox>
              </li>
            </ul>
          </div>
        </div>
        <div class="row ">
          <div class="col-sm-12 text-center mt-3">
            <button class="search-button" (click)="closeFilterBottomSheet('filter')">
              <span>Apply</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Input fields card end -->
  </div>
  
  <!-- travel -->
  <!-- <div *ngIf="dialogData.popupType == popupType.travel">
    
    <div class="informationpopup">
      <div class="heading">
        <h4>Schengen Countries Information</h4>
        <span class="float-right" (click)="close()">X</span>
      </div>
      <div class="travel_information">
        <p>
          Travel Insurance to Schengen countries is mandatory for every traveler. A group of 26 European countries fall
          into the Schengen Agreement, thus eliminating the internal border which controls them. We have additionally
          listed 4 countries below the Schengen country list which have similar travel insurance parameters as Schengen
          countries.
        </p>
      </div>
      <h4>
        List of European Union Member States which are not Schengen States but with similar travel insurance parameters.
      </h4>
      <div class="travel_information">
        <p> <span *ngFor="let state1 of europeanStates; last as isLast">{{state1}}<span
          *ngIf="!isLast">,</span></span></p>
      </div>
      <h4>List of Schengen States :</h4>
      <div class="travel_information">
        <p> <span *ngFor="let state of schengenStates; last as isLast">{{state}}<span
          *ngIf="!isLast">, </span></span></p>
      </div>
    </div>
  </div> -->