<div class="cardScroll mat-typography" *ngIf="pagedList.length > 0">
    <mat-card class="innerbox borderinnerbox"
        *ngFor="let list of pagedList.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)">
        <div class="paddingDiv">
            <div class="row customDiv">
                <ng-container *ngFor="let item of displayedColumns">
                    <div class="col-sm-3" *ngIf="banColumns(item.columnDef)">
                        <mat-label class="mtlabel">{{item.header}}</mat-label>
                        <p>{{(item.columnDef != 'Amount') && (item.columnDef != 'TotalPremium') && (item.columnDef !=
                            'Premium') ? list[item.columnDef] == '' ? '-' : item.columnDef == 'ProposalStatus' ?
                            addSpace(list[item.columnDef]) : list[item.columnDef] :
                            '₹' + utilityService.convertStringtoCurrency(list[item.columnDef])}}</p>
                    </div>
                </ng-container>
            </div>
            <mat-divider></mat-divider>
            <div class="row mt-3">
                <div class="col">
                    <ng-container *ngFor="let b of buttonData">
                        <button class=" checkboxPending downloadPending" (click)="emitData(list, b.label)"
                            *ngIf="b.label != 'Add To Pay' && evalConditionForMyPolicy(list, b.expression ?? true)">
                            <div class="myDownload">
                                <span class="webdownload">{{b.label}}</span>
                            </div>
                            <span class="mobiledownload"><img src="{{b.imgPath}}" alt="Download Policy"></span>
                        </button>
                        <!-- <button class=" checkboxPending downloadPending"  (click)="emitData(list, b.label)"
                        *ngIf="b.label != 'Add To Pay' && evalConditionForMyPolicy(list, b.expression ?? true) && (b.type ==='button')">
                        <div class="myDownload">
                            <span class="webdownload">{{b.label}}</span>
                        </div>
                        <span class="mobiledownload" >Get Details</span>
                    </button> -->
                        <span *ngIf="b.label == 'Add To Pay' && evalConditionForMyPolicy(list, b.expression ?? true)">
                            <mat-checkbox class="cstmCheckbox checkboxPending" [checked]="list.isChecked"
                                (change)="emitData({selected: $event.checked, rowData: list, id: list.id}, 'Add To Pay')">
                                Add To Pay
                            </mat-checkbox>&nbsp;
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
    </mat-card>
</div>
<div *ngIf="pagedList.length == 0" class="NoRecordFoundMainDiv">
    <mat-card class="d-flex align-items-center NoRecordFound" >
        <div class="pl-3 NoRecordFoundData">
            <img src="{{imagePathService.NoRecordFoundImg}}" alt="No Record Found"
                class="noRecordImg mb-3">
            <h3 class="text-center mb-2 paraMessage mat-typography " > No data to show</h3>
            <h4 class="mb-0 messagePara mat-typography "> Try adjusting your search or filter to find your report</h4>
        </div>
    </mat-card>
</div>
<div class="cardPag" *ngIf="pagedList.length != 0">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>