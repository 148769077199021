import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookedBusinessReportComponent } from './booked-business-report/booked-business-report.component';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, NativeDateAdapter,MatOptionModule,MatRippleModule } from '@angular/material/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptorService } from '../shared/interceptors/api-interceptor.service';
import { ApiRequestInteceptorService } from '../shared/interceptors/api-request-inteceptor.service'
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatChipsModule } from '@angular/material/chips';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatPaginatorModule } from '@angular/material/paginator';
// import { MaterialModule } from './material/material.module';
import { LayoutModule } from './../layout/layout.module';
const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    BookedBusinessReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatTableModule,
    CommonModule,
    BrowserModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    A11yModule,
    DatePipe,
    HttpClientModule,
    MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatTabsModule,
    MatPaginatorModule,
    MatOptionModule,
    MatRippleModule,
    BrowserAnimationsModule,
    LayoutModule,
   

  ],
  exports:[
   MatSelectModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatSliderModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatTabsModule,
    MatPaginatorModule,
    MatOptionModule,
    MatRippleModule,
    LayoutModule,
    BookedBusinessReportComponent
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestInteceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, 
      useValue: 'en-GB' 
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_FORMATS,
    },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    DatePipe,
    provideClientHydration()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BBRModule { }
