// Use enums to declare modal that can be used between shared components
export enum patterns {
    email = "\\S+@\\S+\\.\\w+",
    number = '/^[0-9]+$/', //Use reg ex from constants
    text = '^[a-zA-Z ]+$',// Use regex from constants
    panNo = "[A-Z]{5}[0-9]{4}[A-Z]{1}",
    GSTIN = '^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$',
    UIN = '/^[0-9]{4}[A-Z]{3}[0-9]{5}[UO]{1}[N][A-Z0-9]{1}$/',
    pincode = '^[1-9][0-9]{5}$',
    weight = '^((?!(0))[0-9]{1,3})$',
    phoneNumber = '^([0|\+[0-9]{1,5})?([6-9][0-9]{9})$',
    ificCode = '^[A-Z]{4}0[A-Z0-9]{6}$',
    accountNumber = '[0-9]{9,18}',
    heightFeetInches = '^[1-7][\.]([0-9]|1[0-1])$',
    alphabetsOnly = '^[a-zA-Z ]+$' //'^[a-zA-Z \-\']+',

}

export enum validationPatterns {
    required = 'required',
    pattern = 'pattern',
    minlength = 'minlength',
    maxlength = 'maxlength'
}
export enum healthToggle {
    Yes = 'Yes',
    No = 'No',
}
export enum ProductCode {
    HAP = 42,
    MaxProtect = 34,
    GS = 40,
    ASP = 22,
    HB = 20,
    HEP = 33,
    FS = 23,
    CS = 38
}

export enum ProductType {
    ZeroTat = 'ZeroTat',
    Indemnity = 'Indemnity',
    Travel = 'Travel',
}