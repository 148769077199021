import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { validationPatterns, patterns, healthToggle, ProductCode, ProductType} from "../enums/healthEnum";
import { BehaviorSubject } from "rxjs";
import { Constants } from "../constants/constants";
import { environment } from '../../../environments/environment';
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { userEnums } from "../enums/userEnums";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  showSnackbar = (message: string) => {
    this.snackBar.open(message, 'Ok', {
      duration: 2000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  };
  constructor(private snackBar: MatSnackBar, private router: Router, private datePipe: DatePipe,
    private storageService: StorageService) { }

  ErrorMessage = new BehaviorSubject<string>('Success');

  // getErrorMessage() {
  //   return this.ErrorMessage.asObservable();
  // }
  getUTCTime() {
    let currTimestamp = Date.now(), //1482905176396
    utcDateString = (new Date(currTimestamp)).toUTCString();
    return new Date(utcDateString).getTime();
  }
  
  dateformate(date:any) {
    // Split the input date string
    const [day, month, year] = date.split('/');

    // Array of month names
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];

    // Get the abbreviated month name based on the month index
    const abbreviatedMonth = monthNames[parseInt(month, 10) - 1];

    // Construct the formatted date string
    const formattedDate = `${day}-${abbreviatedMonth}-${year}`;

    return formattedDate;
  }

  formatDate(date:any) { //dd-mmm-yyyy
    if (!date) return null;
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + "-" + this.monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  }

  formateDateWithMonth(date:any) {
    // Input date string
    let formattedDate = '';
    if (date) {
      const dateString = date;
      // Split the date string into day, month, and year
      const [day, month, year] = dateString.split('/');
      // Create a Date object using the components
      const dateObject = new Date(`${year}-${month}-${day}`);
      // Format the date to "dd-MMM-yyyy"
      formattedDate = dateObject.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s/g, '-');
    }
    return formattedDate;
  }

  formateDate(inputDate:any) {
    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Add 1 because months are zero-based
    const year = date.getUTCFullYear();

    // Format day and month as two digits
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Create the DD MM YYYY formatted date string
    const formattedDate = `${formattedDay} ${formattedMonth} ${year}`;
    return formattedDate; // Output: "01 05 1982"

  }

  formatDateWithSpace(date:any) { //dd mmm yyyy
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + " " + this.monthNames[currDate.getMonth()] + " " + currDate.getFullYear();
  }


  formatToMonthDateYearWithSlash(date: string) { //mm/dd/yyyy
    let stringDate = date;
    let splitDate = stringDate.split('/');
    let year = splitDate[2];
    let month = splitDate[1];
    let day = splitDate[0];
    let finalDate;
    finalDate = month + "/" + day + "/" + year;
    return !finalDate.includes('T') ? new Date(finalDate) : finalDate
  }

  formatToMonthDateYearHyphen(date: string) { //mm-dd-yyyy
    let stringDate = date;
    let splitDate = stringDate.split('-');
    let year = splitDate[0];
    let month = splitDate[1];
    let day = splitDate[2];
    let finalDate;
    finalDate = month + "-" + day + "-" + year;
    return !finalDate.includes('T') ? new Date(finalDate) : finalDate
  }

  formatDateWithDash(date:any) { //yyyy-mm-dd
    const originalDate = new Date(date);

    if (!date) {
      return null;
    } else if (originalDate.toString() == 'Invalid Date') {
      let dateParts = date.split("/");
      let dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      return this.datePipe.transform(dateObject, 'yyyy-MM-dd');
    } else {
      return this.datePipe.transform(originalDate, 'yyyy-MM-dd');
    }
  }

  //input date in form dd/mm/yyyy and output yyyy-mm-dd
  formatewithDate(date:any) {
    let dateString = date;
    let parts = dateString.split('/'); // Split the string into parts using '/'
    let formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`; // Rearrange the parts to 'yyyy-mm-dd'
    return formattedDate;
  }

  formatDate3(date:any) { //yyyy-mm-dd
    let data = date.split("/");
    let finalDate = data[2] + "-" + data[1] + "-" + data[0];
    return finalDate
    // var currDate = new Date(date);
    // return currDate.getFullYear() + "-" + (this.getMonth(currDate)) + "-" + this.getMyDate(currDate.getDate());
  }

  convertDateFormat(formatDate:any) {
    var parts = formatDate.split('/');
    let finalPart = parts[1] + '/' + parts[0] + '/' + parts[2];
    return finalPart
  }

  DateWithSlash(date:any) { //yyyy/mm/dd
    const originalDate = new Date(date);

    // Get the year, day, and month components
    const year = originalDate.getFullYear();
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1

    // Create the formatted date string in yyyy-dd-mm format
    return `${year}-${month}-${day}`;
  }

  DateWithSlash1(date:any) { //dd-mm-yyyy
    const originalDate = new Date(date);

    // Get the year, day, and month components
    const year = originalDate.getFullYear();
    const day = originalDate.getDate().toString().padStart(2, '0');
    const month = (originalDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based, so we add 1

    // Create the formatted date string in dd-mm-yyyy format
    return `${day}-${month}-${year}`;
  }

  formatDateWithSlash(date:any) { //dd/mmm/yyyy
    let currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + "/" + this.monthNames[currDate.getMonth()] + "/" + currDate.getFullYear();
  }
  
  formatDateWithDash_dmy(date:any) { // dd-mmm-yyyy
    // Check if the input date already matches the desired format
    const match = /^(\d{2})-(\w{3})-(\d{4})$/.exec(date);
    if (match) {
        // If it's already in the desired format, return it as is
        return date;
    }

    let currDate = new Date(date);
    // Transform the date to the desired format
    return this.getMyDate(currDate.getDate()) + "-" + this.monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
}


  getMonth(value:any) {
    const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbreviation = value; // Example: November

    // Find the numerical month by searching the array
    const numericalMonth = monthAbbreviations.indexOf(monthAbbreviation) + 1; // Add 1 to match 1-based month numbering

    if (numericalMonth > 0) {
      // Check if the abbreviation was found
      const formattedMonth = String(numericalMonth).padStart(2, '0');
      return formattedMonth; // Output: "11" for November
    } else {
      return 'Invalid month abbreviation';
    }
  }

  // Comparison of two dates
  dateDiff(d1:any, d2:any) {
    return d1 < d2;
  }

  fetchMonth(date:any) {
    let tempMonth: string = this.monthNames[date.getMonth()];
    let monthId = this.monthNames.findIndex(data => data == tempMonth);
    return monthId + 1;
  }

  getMyDate(day:any) {
    return day < 10 ? '0' + day : '' + day;
  }

  getMonthInNumber(month:any): number {
    let months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
    month = month.toLowerCase();
    let monthId = months.findIndex(data => data == month);
    return monthId + 1;
  }
  formatDate1(date:any) { //dd-mmm-yyyy
    var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var currDate = new Date(date);
    return this.getMyDate(currDate.getDate()) + "-" + monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  }

  formatWithIncrementDate(inputDate:any, numberOfDays:any) {
    let date = new Date(inputDate)
    // Add a day
    let newDate = date.setDate(date.getDate() + numberOfDays)
    return new Date(newDate)
  }

  // formatDate(date) { //dd-mmm-yyyy
  //   var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  //   var currDate = new Date(date);
  //   return this.getMyDate(currDate.getDate()) + "-" + monthNames[currDate.getMonth()] + "-" + currDate.getFullYear();
  // }

  calculateAge(DOB:any) {
    let today = new Date();
    let birthDate = new Date(DOB);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  setGender(relationship:any, relationshipsArray:any): string {
    let gender = '';
    relationshipsArray.forEach((ele:any, index:any) => {
      if (ele.RelationshipName == relationship) {
        gender = ele.Gender;
      }
    });
    return gender;
  }

  // To get exact min and max date as per years passed
  // if need to add 11 month more
  getMinMaxDate(maxYear: any, minYear?: any, maxMonth?: any, minMonth?: any,) {
    let defaultMaxDate = new Date().getFullYear();
    let defaultMinDate = new Date().getFullYear() - 100;
    let maxDate = maxYear ? maxYear : defaultMaxDate;
    let minDate = minYear ? minYear : defaultMinDate;
    let d = new Date();
    let tempMaxDate, tempMinDate;
    if (maxMonth) {
      d.setFullYear(maxDate);
      let a = d.getMonth() - maxMonth;
      tempMaxDate = d.setMonth(a);
      d.setDate(d.getDate() + 1);
    } else {
      tempMaxDate = d.setFullYear(maxDate);
      d.setDate(d.getDate() + 1);
    }
    if (minMonth) {
      d.setFullYear(minDate);
      let a = d.getMonth() - minMonth;
      tempMinDate = d.setMonth(a);
      d.setDate(d.getDate() + 1);
    } else {
      d.setFullYear(minDate);
      tempMinDate = d.setMonth(d.getMonth());
      d.setDate(d.getDate() + 1);
    }
    return { maxDate: new Date(tempMaxDate), minDate: new Date(tempMinDate) }
  }

  compareDates(date1:any, date2:any) {
    return new Date(date1) >= new Date(date2);
  }

  easyIncomeReader(value: string): string {
    if (value.includes('-')) {
      let [start, end] = value.split(' - ');
      start = this.convertStringtoCurrency(start);
      end = this.convertStringtoCurrency(end);
      return `${start} - ${end}`
    }
    else if (value.includes('Upto')) {
      let [_, end] = value.split(' ');
      end = this.convertStringtoCurrency(end);
      return `Upto ${end}`
    } else if (value.includes('above')) { }
    let [start, _] = value.split(' ');
    start = this.convertStringtoCurrency(start);
    return `${start} and above`
  }

  convertStringtoCurrency(input:any) {
    if (input) {
      let result = input.toString().split('.');
      let lastThree = result[0].substring(result[0].length - 3);
      let otherNumbers = result[0].substring(0, result[0].length - 3);
      if (otherNumbers != '') lastThree = ',' + lastThree; let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
      if (result.length > 1) { output += "." + result[1]; }
      return output;
    }
  }

  isEmptyOrNull(data:any) {
    if (data instanceof Date) {
      // If it's a date object, consider it non-empty
      return false;
    }
    else if (typeof (data) == 'boolean') {
      return false;
    }
    else if (data === undefined || data == null || data == '' || data.length <= 0 || Object.keys(data).length === 0) {
      return true;
    } else {
      return false;
    }
  }

  // restrict spaces
  restrictSpace(event:any) {
    let spaceRegex = /\s/g;
    if (event && event.currentTarget && event.currentTarget && event.currentTarget.value.match(spaceRegex)) {
      event.currentTarget.value = event.currentTarget.value.replace(spaceRegex, '');
    }
  }

  // To restrict user to enter only numbers
  numberOnly(event:any): boolean {
    let allowNumbersFlag: boolean;
    allowNumbersFlag = this.allowOnlyNumbers(event);
    return allowNumbersFlag;
  }

  // To restrict user to only enter numbers  for dynamic controls
  // To restrict user to enter only numbers
  dynamicControlsNumberOnly(event:any, numberValidationRequired: boolean): boolean {
    let allowNumbersFlag: boolean =false;
    if (numberValidationRequired) {
      allowNumbersFlag = this.allowOnlyNumbers(event);
    }
    else if (!numberValidationRequired) {
      allowNumbersFlag = event;
    }

    return allowNumbersFlag;
  }

  dynamicControlsNumberDecimalOnly(event:any, numberValidationRequired: boolean): boolean {
    let allowNumbersFlag: boolean = false;
    if (numberValidationRequired) {
      allowNumbersFlag = this.allowOnlyNumbersDecimal(event);
    }
    else if (!numberValidationRequired) {
      allowNumbersFlag = event;
    }

    return allowNumbersFlag;
  }

  allowOnlyNumbers(event:any): boolean {
    let numberflagCheck: boolean = false;
    let inputValue;
    let numberRegex = /\D/g;
    if (event && event.currentTarget && event.currentTarget.value) {
      inputValue = event.currentTarget.value.replace(numberRegex, '');
      event.currentTarget.value = inputValue;
      numberflagCheck = true;
    }
    return numberflagCheck;
  }

  allowOnlyNumbersDecimal(event:any): boolean {
    // const charCode = (event.which) ? event.which : event.keyCode;
    // ASCII Codes for refrence: 48: 0; 57: 9; 31: Unit separator
    // if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    //   return false;
    // }
    let numberflagCheck: boolean = false;
    let inputValue;
    let numberRegex = /[^0-9.]+/g;
    if (event && event.currentTarget && event.currentTarget.value) {
      inputValue = event.currentTarget.value.replace(numberRegex, '');
      event.currentTarget.value = inputValue;
      numberflagCheck = true;
    }
    return numberflagCheck;
  }

  dynamicControlsAlphaOnly(event:any, textValidationRequired: boolean): boolean {
    let allowTextRequired: boolean = false;
    let alphaRegex = /[^A-Za-z_ ]/i;
    if (textValidationRequired && event && event.currentTarget && event.currentTarget.value) {
      let validatedText = event.currentTarget.value.replace(alphaRegex, '');
      event.currentTarget.value = validatedText;
    }
    else if (!textValidationRequired) {
      allowTextRequired = true;
    }
    return allowTextRequired
  }

  // To check while pasting input
  onPaste(event: ClipboardEvent, onlyAlphaAllowed: boolean, onlyNumAllowed: boolean) {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text/plain');
    if ((onlyAlphaAllowed && !this.isValidText(pastedText)) || (onlyNumAllowed && !this.isValidNumber(pastedText))) {
      event.preventDefault();

    }
  }

  // handle tab events
  nextElementFocus(id: string): string {
    //i stands for insured
    // s stands for search customer form
    // a stands for applicant block top 
    // c & p stands for correspondence and permanent block
    // o,n,k stands for gst, nominee and apointee block
    let customId: string = '';
    let delimeter = id[0];
    // Below are the field for which tab index is failing. Please check respective json to get control ame
    if (id == 'i3' || id == 'a3' || id == 'c3' || id == 'c5' || id == 's1' || id == 'p3' || id == 'p5' || id == 'n2' || id == 'k2') {
      let number = parseInt(id[1]);
      let nextId = number + 1;
      // By using below code we are focusing on next element
      // a4 is applicant dob ---> correspondence full name
      customId = delimeter + nextId.toString();
      return customId;
    }
    return customId;
    // }

  }

  isValidText(text: string): boolean {
    const pattern = Constants.textPatternRegExp;
    return pattern.test(text);
  }

  isValidNumber(text: string): boolean {
    const pattern = Constants.numberPatternRegExp;
    return pattern.test(text);
  }

  // sorting columns alphabetically
  sortDetails(array:any, colName:any) {
    return array.sort(function (a:any, b:any) {
      if (a[colName] < b[colName]) {
        return -1;
      }
      if (a[colName] > b[colName]) {
        return 1;
      }
      return 0;
    });
  }

  subtractYears(date:any, years:any) {
    date.setFullYear(date.getFullYear() - years);
    return date;
  }

  subtractMonths(date:any, months:any) {
    date.setMonth(date.getMonth() - months)
    return date;
  }

  subtractDays(date:any, days:any) {
    date.setDate(date.getDate() - days);
    return date;
  }
  addDays(date:any, days:any) {
    date.setDate(date.getDate() + days);
    return date;
  }
  // validations
  // Required field validation
  requiredFieldValidation() {
    return (control: AbstractControl) => {
      if (typeof (control.value) == 'object') {
        return null;
      } else if (typeof (control.value) == 'boolean') {
        return null;
      }
      else {
        let value = control.value ? (control.value).trim() : '';
        if (value == '') {
          return { required: true }
        }
        return null;
      }
    }
  }

  // To check whether user has entered 0 number in input field or not
  zeroValidation() {
    return (control: AbstractControl) => {
      let value = parseInt(control.value);
      if (value == 0) {
        return { invalidNumber: true }
      }
      return null;

    }
  }

  // Creation of correlation Id
  createCorrelationId() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  truncateAfterWhiteSpace(value: string): string {
    if (value.indexOf(' ')) {
      const index = value.indexOf(' ');
      if (index !== -1) {
        return value.substring(0, index);
      } else {
        return value;
      }
    } else {
      return value;
    }

  }

  truncateAfterT(value: string): string {
    if (value.indexOf('T')) {
      const index = value.indexOf('T');
      if (index !== -1) {
        return value.substring(0, index);
      } else {
        return value;
      }
    } else {
      return value;
    }
  }

  removeUnderScore(value: string): string {
    if (value.includes('_')) {
      return value.replace(/_/g, ' ');
    } else {
      return value;
    }
  }

  // for send payment link summary
  easyAmountReader(value:any): string {
    let val = Number(value);
    if (val >= 9999999999) {
      return 'Unlimited';
    } else if (val >= 10000000) {
      val = val / 10000000;
      return `₹ ${val % 1 === 0 ? val.toFixed(0) : val.toFixed(1)} Cr`;
    } else if (val >= 100000) {
      val = val / 100000;
      return `₹ ${val % 1 === 0 ? val.toFixed(0) : val.toFixed(1)} L`;
    } else if (val >= 1000) {
      val = val / 1000;
      return `₹ ${val % 1 === 0 ? val.toFixed(0) : val.toFixed(1)} K`;
    } else {
      return `₹ ${Math.floor(val)}`;
    }
  }

  identifyEnvironmentType(token: string): Object {
    let source = {
      env: '',
      port: '',
      token: ''
    };
    source.env = environment.environment;
    source.port = window.location.hostname === 'localhost' ? window.location.port : '';
    source.token = token;
    return source;
  }

  amountinWords(val: number): string {
    if (val >= 9999999999) {
      return 'UNLIMITED';
    }
    else if (val >= 10000000) {
      val = val / 10000000;
      return `₹ ${Math.floor(val)} Crore`;
    }
    else if (val >= 100000) {
      val = val / 100000;
      return `₹ ${Math.floor(val)} Lakhs`;
    }
    else
      return `₹ ${Math.floor(val)}`;
  }


  isUndefinedORNull(data: any) {
    if (data === undefined || data === null || data.length <= 0) {
      return true;
    } else {
      return false;
    }
  }
  // To find whether product is indemnity or zero tat
  productTypeStatus(productCode: number): string {
    let productType: string = '';
    let url = this.router.url.split('/')[1];
    if(url == 'health') {
      if (productCode == 23 || productCode == 38) {
        productType = ProductType.ZeroTat;
      }
      else{
        productType = ProductType.Indemnity;
      }
    }else if(url == 'travel') {
      productType = ProductType.Travel;
    }
    return productType;
  }

  getStandardDateFormat(date:any,format:any) {
    let dateString = date.split('-');
    let formattedDate:string ='';
    if(format == 'dd-mm-yyyy') {
      formattedDate = `${dateString[2]}-${dateString[1]}-${dateString[0]}`;
    }else if(format == 'dd/mm/yyyy') {
      
    }else if(format == 'dd-MMM-yyyy') {
      
      let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      let [day, month, year] = date.split('-');
      formattedDate = `${year}-${monthNames.indexOf(month) + 1}-${day}`;
    }else if(format == 'dd/MMM/yyyy') {
      
    }
    return new Date(formattedDate);
  }

  getFormattedDate(date:any,format:any) {
    // console.log('date formate hree ', this.datePipe.transform(date, 'yyyy-MM-dd'))
    let formattedDate;
    if(format == 'dd-mm-yyyy') {
      formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    }else if(format == 'dd/mm/yyyy') {
      formattedDate = this.datePipe.transform(date, 'dd/MM/yyyy');
    }else if(format == 'dd-MMM-yyyy') {
      formattedDate = this.datePipe.transform(date, 'dd-MMM-yyyy');
    }else if(format == 'yyyy-mm-dd') {
      formattedDate = this.datePipe.transform(date, 'yyyy-mm-dd');
    }
    return formattedDate;
  }
  // Map Sub Product Code
  mapSubProductCode(productType: string) {
    let productCode: number = 0;
    switch (productType) {

      case 'hap': {
        productCode = ProductCode.HAP;
        break;
      }
      case 'gs': {
        productCode = ProductCode.GS;
        break;
      }
      case 'asp': {
        productCode = ProductCode.ASP;
        break;
      }
      case 'hep': {
        productCode = ProductCode.HEP;
        break;
      }
      case 'max': {
        productCode = ProductCode.MaxProtect;
        break;
      }
      case 'hb': {
        productCode = ProductCode.HB;
        break;
      }
      case 'cs': {
        productCode = ProductCode.CS;
        break;
      }
      case 'fs': {
        productCode = ProductCode.FS;
        break;
      }
    }
    this.storageService.setAESEncryptedData(userEnums.SubProductCode, productCode);
  }

  checkDuplicate(values:any, key:any) {
    let valueArr = values.map(function(item:any){ return item[key] });
    let isDuplicate = valueArr.some(function(item:any, idx:any){ 
      return valueArr.indexOf(item) != idx 
    });
    return isDuplicate;
  }

  
  toPascalCase(input: string): string {
    return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
  }


}
