import { Component, ViewEncapsulation , AfterViewInit, ElementRef, Input, OnChanges, OnInit, QueryList, Renderer2, ViewChildren, OnDestroy, DoCheck} from '@angular/core';

import { MatSelect } from '@angular/material/select';
import { userEnums } from '../../../../src/app/shared/enums/userEnums';
import { StorageService } from '../../../../src/app/shared/services/storage.service';
// import { AgentV1Service } from 'src/app/shared/proxy-services/agent-v1.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UtilityService } from '../../../../src/app/shared/services/utility.service';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../layout/popup/popup.component'
import { popupType } from '../../../../src/app/shared/enums/popupEnums';
import { BookedBusinessReportService } from  '../services/booked-business-report.service'
import { Constants } from '../../../../src/app/shared/constants/constants';
import { DownloadService } from '../../../../src/app/shared/services/download.service';
import { BookedBusinessReport, Policy, downloadPDFGeneric } from '../interfaces/booked-business-report';
import { imagePath } from '../../../../src/app/shared/modal/imagePath';
import {ImagePathService} from '../../../../src/app/shared/services/image-path.service';
@Component({
  selector: 'app-booked-business-report',
  templateUrl: './booked-business-report.component.html',
  styleUrls: ['./booked-business-report.component.scss','../../../styles.scss','../../../assets/bootstrap.min.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class BookedBusinessReportComponent implements OnInit,OnChanges,OnDestroy{

    @Input() externalToken: string = '';
    @Input() productTypeList : string[] = [];
    inputReceived:boolean = false;
    // infoImgSrc = require("../../../assets/images/BBR/Edit.svg");
    private interval: any;
    bookFormGroup!: FormGroup;
    agentIMID: string ='';
    imgURL =  `${imagePath.baseUrl}${imagePath.bbrImg}`;
    // imgURL =  "https://cldilmobilapp01.insurancearticlez.com/ipartnermsite/customComponentProj/assets/images/BBR/Edit.svg"
    // productTypeList: { productName: string; productCode: number }[] = [];
    //travelList: { productName: string; productCode: number }[];
    //healthProductList: { productName: string; productCode: number; isProductMapped: any; }[];
    //selectList: any = [];
    isSelected: boolean = false;
    isSubmitted: boolean = false;
    isSearchVisible: boolean = false;
    toggleValue: 'table' | 'card' = 'card';
    minMax: { minDate: Date, maxDate: Date } = {minDate: new Date(), maxDate: new Date()};
    reportList: Policy[] = [];
    filteredData: Policy[] = [];
    product: string = '';
    startDate: Date = new Date();
    endDate: Date = new Date();
    phText: string[] = ['Search for Business Type',
      'Search for Policy Endorsement Type',
      'Search for Policy Number',
      'Search for Policy Cover Note No',
      'Search for Customer Name']
    searchPlaceHolder: string = '';
    i: number = 0;
    inputValue: string = '';
    businessFilter: string[] = [];
    policyFilter: string[] = [];
    businessFilterList = ['New Business', 'Renewal Business', 'Roll over']
    policyFilterList = ['Issued', 'Renewed', 'Endorsed', 'Cancelled'];
    scrolling: boolean = false;
    showCancel: boolean = false;
    policyCount: string ='';
    businessAmount: string = '';
    cardBtnInfo = [{
      label: 'Download Policy',
      imgPath: `${imagePath.baseUrl}${imagePath.downloadPolicyImg}`,//'assets/images/myPolicy/downloadPolicy.svg'
    }]
    tableBtnInfo = [{
      label: 'Download Policy',
      imgPath: `${imagePath.baseUrl}${imagePath.downloadImg}`,//'assets/images/BBR/download.svg',
      tooltip: 'Download'
    }]
    openfilterMenuListFlag:boolean = false;
    // displayCol: string[] = ['ProductClass', 'ProductSubClass', 'PolicyBusinessType', 'PolicyNumber', 'PolicyCoverNoteNo',
    //   'CustomerFullName', 'NetGwp', 'IndicativePayoutRate', 'Action', 'PolicyEndorsementType', 'PolicyReportingDate', 'PolicyStartDate', 'PolicyEndorsementDate']
  
    displayCol = [
      {
        columnDef: 'ProductClass',
        header: 'Product Class',
        cell: (element: any) => `${element.ProductClass}`,
      },
      {
        columnDef: 'ProductSubClass',
        header: 'Product Sub Class',
        cell: (element: any) => `${element.ProductSubClass}`,
      },
      {
        columnDef: 'PolicyBusinessType',
        header: 'Business Type',
        cell: (element: any) => `${element.PolicyBusinessType}`,
      },
      {
        columnDef: 'PolicyNumber',
        header: 'Policy Number',
        cell: (element: any) => `${element.PolicyNumber}`,
      },
      {
        columnDef: 'PolicyCoverNoteNo',
        header: 'Policy Cover Note No',
        cell: (element: any) => `${element.PolicyCoverNoteNo}`,
      },
      {
        columnDef: 'CustomerFullName',
        header: 'Customer Name',
        cell: (element: any) => `${element.CustomerFullName}`,
      },
      {
        columnDef: 'NetGwp',
        header: 'Net GWP',
        cell: (element: any) => `${element.NetGwp}`,
      },
      {
        columnDef: 'IndicativePayoutRate',
        header: 'Commission (%)',
        cell: (element: any) => `${element.IndicativePayoutRate}`,
      },
      {
        columnDef: 'Action',
        header: 'Action',
        cell: '',
      },
      {
        columnDef: 'PolicyEndorsementType',
        header: 'Policy Endorsement Type',
        cell: (element: any) => `${element.PolicyEndorsementType}`,
      },
      {
        columnDef: 'PolicyReportingDate',
        header: 'Policy Reporting Date',
        cell: (element: any) => `${element.PolicyReportingDate}`,
      },
      {
        columnDef: 'PolicyStartDate',
        header: 'Policy Start Date',
        cell: (element: any) => `${element.PolicyStartDate}`,
      },
      {
        columnDef: 'PolicyEndorsementDate',
        header: 'Policy Endorsement Date',
        cell: (element: any) => `${element.PolicyEndorsementDate}`,
      }
    ]
    firstTime:boolean = false;

    constructor(private formBuilder: FormBuilder,
      private renderer: Renderer2,
      private storageService: StorageService,
      public imagePathService:ImagePathService,
      // private agentV1Service: AgentV1Service,
      public utilityService: UtilityService,
      private dialog: MatDialog,
      private bookedBusinessReportService: BookedBusinessReportService,
      private downloadService: DownloadService,
      private el: ElementRef) {
        this.initFormGroup();
        this.getInitialDates();
        
       }
  
  
    ngOnInit() {
      console.log("ngOnInit");

      window.addEventListener('scroll', this.onWindowScroll.bind(this), { passive: true });
      console.log('productList:- ', this.productTypeList);
      console.log('authToken:- ', this.externalToken);

      
      // this.getProductList();
      
      if(!this.utilityService.isUndefinedORNull(this.externalToken) && !this.utilityService.isUndefinedORNull(this.productTypeList) && !this.firstTime)
      {
        this.inputReceived = true;
        // console.log('authToken:- ', this.externalToken);
        localStorage.setItem('authToken',this.externalToken);
        this.productTypeList = typeof(this.productTypeList) == 'string' ? JSON.parse(this.productTypeList) : this.productTypeList;
        this.bookFormGroup.get('productType')?.setValue(this.productTypeList[0]);
        this.firstTime = true;
        this.getReportList();
      }
      this.setPlaceHolderText();
      this.interval = setInterval(() => { this.setPlaceHolderText(); }, 2000);
    }
  
    ngOnChanges(changes:any) {
      console.log("ng on change",changes);
      console.log('productList:- ', this.productTypeList);
      console.log('authToken:- ', this.externalToken);
      if(!this.utilityService.isUndefinedORNull(this.externalToken) && !this.utilityService.isUndefinedORNull(this.productTypeList) && !this.firstTime)
      {
        this.inputReceived = true;
        // console.log('authToken:- ', this.externalToken);
        localStorage.setItem('authToken',this.externalToken);
        this.productTypeList = typeof(this.productTypeList) == 'string' ? JSON.parse(this.productTypeList) : this.productTypeList;
        this.bookFormGroup.get('productType')?.setValue(this.productTypeList[0]);
        this.firstTime = true;

        this.getReportList();

      }
      // changes.prop contains the old and the new value...
    }
   
    changeStyle(idname:string)
    {
      document.getElementById(idname)?.classList.remove("mat-button-toggle-appearance-standard")
      // this.el.nativeElement.getElementById(idname).classList.remove("mat-button-toggle-appearance-standard");
    }
  
    onWindowScroll() {
      this.scrolling = true;
  
      if (window.scrollY === 0) {
        this.scrolling = false;
      }
    }
  
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  
    setPlaceHolderText() {
      this.searchPlaceHolder = this.phText[this.i];
      this.i++;
      if (this.i == this.phText.length) {
        this.i = 0;
      }
    }
  
    inputFocus() {
      this.showCancel = true;
    }
  
    inputBlur() {
      this.showCancel = false;
    }
  
    preventInput(event: KeyboardEvent): void {
      const allowedKeys = ['ArrowLeft', 'ArrowRight', 'Tab'];
      if (!allowedKeys.includes(event.key)) {
        event.preventDefault();
      }
    }
    queryData() {
      const query = this.inputValue;
  
      if (!query && (this.utilityService.isEmptyOrNull(this.businessFilter) && this.utilityService.isEmptyOrNull(this.policyFilter))) {
        this.filteredData = this.reportList;
      } else if (!query && (!(this.utilityService.isEmptyOrNull(this.businessFilter)) || !(this.utilityService.isEmptyOrNull(this.policyFilter)))) {
        this.filterLogic();
      } else if (query && (this.utilityService.isEmptyOrNull(this.businessFilter) && this.utilityService.isEmptyOrNull(this.policyFilter))) {
        this.filteredData = this.reportList.filter(item => {
          return Object.values(item).some(val =>
            val.toString().toLowerCase().includes(query.toLowerCase())
          );
        });
      } else {
        this.filteredData = this.filteredData.filter(item => {
          return Object.values(item).some(val =>
            val.toString().toLowerCase().includes(query.toLowerCase())
          );
        });
      }
    }
  
    filterLogic() {
      if (!(this.utilityService.isEmptyOrNull(this.businessFilter)) && this.utilityService.isEmptyOrNull(this.policyFilter)) {
        this.filteredData = this.reportList.filter(item => this.businessFilter.includes(item['PolicyBusinessType'].toLowerCase()))
      } else if (this.utilityService.isEmptyOrNull(this.businessFilter) && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
        this.filteredData = this.reportList.filter(item => this.policyFilter.includes(item['PolicyEndorsementType'].toLowerCase()))
      } else if (!(this.utilityService.isEmptyOrNull(this.businessFilter)) && !(this.utilityService.isEmptyOrNull(this.policyFilter))) {
        this.filteredData = this.reportList.filter(item => this.businessFilter.includes(item['PolicyBusinessType'].toLowerCase())
          && this.policyFilter.includes(item['PolicyEndorsementType'].toLowerCase()))
      }
    }
  
    applyFilter() {
      this.clearInputField();
      this.filterLogic();
    }
  
    searchFilter() {
      this.isSearchVisible = true;
    }
  
    closeClear() {
      this.isSearchVisible = false;
      this.clearInputField();
    }
  
    getReportList() {
      const payload = {
        // IMID: this.bookFormGroup.value.IMID,
        StartDate: this.utilityService.formatDateWithDash(this.bookFormGroup.value.startDate),
        EndDate: this.utilityService.formatDateWithDash(this.bookFormGroup.value.endDate),
        productClass:  this.bookFormGroup.value.productType.toUpperCase() //== "Health" ? "HEALTH" : "HOME"
      }
      this.bookedBusinessReportService.getBookedBusinessReport(payload).subscribe({
        next: (response: BookedBusinessReport) => {

          if (response.StatusCode == Constants.statusCode_success) {
            this.policyCount = String(response.Data.TotalBookedBusinessPolicyCount);
            this.businessAmount = String(response.Data.TotalBookedBusinessAmount);
            this.reportList = response.Data.policies;
            this.filteredData = this.reportList;
          }
          else {
            this.filteredData = [];
            this.policyCount = "0";
            this.businessAmount = "0";
          }
        },
      })
      this.updateInfoCard();
    }
  
    getProductList() {
      // this.productTypeList = this.productTypeList.map(x => x.toUpperCase());
      // const loginCredentials = this.storageService.getAESDecryptedData(userEnums.LoginCredential);
      // this.agentIMID = loginCredentials.username;
      // const agentDetails = this.storageService.getAESDecryptedData(userEnums.AgentDetails);
      // this.productTypeList = this.agentV1Service.productTypeList(agentDetails, '');
      // this.productTypeList.push({ productName: 'Health', productCode: 0 });
      //   this.productTypeList?.push({ productName: 'Home', productCode: 1 });
      //   this.productTypeList?.push({ productName: 'Travel', productCode: 2 });
      //   this.productTypeList?.push({ productName: 'Motor', productCode: 3 });
        
    }
  
    getInitialDates() {
      const currentDate = new Date();
      const min = new Date(currentDate.getFullYear(), 0, 1);
      const max = new Date();
      max.setMonth(new Date().getMonth() + 3);
      this.minMax = { minDate: min, maxDate: max };
    }
  
    // getProductNameFromCode(code: number): string {
    //   if (this.bookFormGroup.value.productType == 1) {
    //     return "Home";
    //   } else {
    //     for (let i of this.selectList) {
    //       if (i.productCode == code) {
    //         return i.productName;
    //       }
    //     }
    //     return String(code);productTypeList
    //   }
    // }
  
    initFormGroup() {
      const SD = this.utilityService.subtractDays(new Date(), 15);
      this.bookFormGroup = this.formBuilder.group({
        // IMID: [this.agentIMID],
        productType: [!this.utilityService.isUndefinedORNull(this.productTypeList)? this.productTypeList[0]:""],
        startDate: [SD],
        endDate: [new Date()]
      })
      this.updateInfoCard();
      // if (this.bookFormGroup.value.productType == 0) {
      //   this.selectList = this.healthProductList;
      //   this.isSelected = true;
      // }
    }
  
    // getSelectList(event) {
    //   if (event.value == 0) {
    //     this.selectList = this.healthProductList;
    //     this.isSelected = true;
    //   } else if (event.value == 2) {
    //     this.selectList = this.travelList;
    //     this.isSelected = true;
    //   } else {
    //     this.isSelected = false;
    //     this.selectList = [];
    //   }
    // }
  
    openSearchBottomSheet() {
      const searchBottomSheet = this.dialog.open(PopupComponent, {
        panelClass: 'my-custom-payment',
        maxWidth: '100vw',
        height: '50%',
        disableClose: true,
        data: {
          popupType: popupType.search,
          data: {
            controls: [
            // {
            //   type: 'input',
            //   label: 'IMID',
            //   readonly: true,
            //   value: this.agentIMID
            // }, 
            {
              type: 'dropdown',
              label: 'Product Type',
              list: this.productTypeList,
              value: this.bookFormGroup.value.productType,
            },
            {
              type: 'rangepicker',
              label: 'Select date range',
              startDate: this.bookFormGroup.value.startDate,
              endDate: this.bookFormGroup.value.endDate,
            }],
            dates: {
              minDate: this.minMax.minDate,
              maxDate: this.minMax.maxDate,
            },
          }
        }
      }
      );
      searchBottomSheet.afterClosed().subscribe((data) => this.patchSearchParameters(data));
    }
  
    patchSearchParameters(data:any) {
      if (data) {
        for (let i of data) {
          if (i.type == 'dropdown') {
            this.bookFormGroup.controls['productType'].setValue(i.value);
          } else if (i.type == 'rangepicker') {
            this.bookFormGroup.controls['startDate'].setValue(i.startDate);
            this.bookFormGroup.controls['endDate'].setValue(i.endDate);
          }
        }
        this.getReportList();
      }
    }
  
    openFilterBottomSheet() {
      const filterBottomSheet = this.dialog.open(PopupComponent, {
        panelClass: 'my-custom-payment',
        maxWidth: '100vw',
        height: '60%',
        disableClose: true,
        data: {
          popupType: popupType.filter,
          data: [{
            label: 'Business Type',
            list: this.businessFilterList,
            filter: this.businessFilter
          },
          {
            label: 'Endorsement Type',
            list: this.policyFilterList,
            filter: this.policyFilter
          }]
        },
      }
      );
      filterBottomSheet.afterClosed().subscribe((data) => this.patchFilterParameters(data));
    }
  
    openFilterPopUp() {
      this.openfilterMenuListFlag = true;
      const element = <HTMLElement> document.getElementById('filterDropdown');
      element.style.display = 'block';
  
      // const filterBottomSheet = this.dialog.open(PopupComponent, {
      //   // panelClass: 'my-custom-payment',
      //   // maxWidth: '100vw',
      //   // height: '60%',
      //   disableClose: true,
      //   data: {
      //     popupType: popupType.filter,
      //     data: [{
      //       label: 'Business Type',
      //       list: this.businessFilterList,
      //       filter: this.businessFilter
      //     },
      //     {
      //       label: 'Endorsement Type',
      //       list: this.policyFilterList,
      //       filter: this.policyFilter
      //     }]
      //   },
      // }
      // );
      // filterBottomSheet.afterClosed().subscribe((data) => this.patchFilterParameters(data));
    }
  
    patchFilterParameters(data:any) {
      if (data) {
        this.businessFilter = data[0].filter;
        this.policyFilter = data[1].filter;
      }
      this.applyFilter();
    }
  
    applyBusinessFilter(isChecked: boolean, value: string) {
      value = value.toLowerCase();
      if (isChecked) {
        if (!this.businessFilter.includes(value)) {
          this.businessFilter.push(value);
        }
      } else {
        const index = this.businessFilter.indexOf(value);
        if (index >= 0) {
          this.businessFilter.splice(index, 1);
        }
      }
      this.applyFilter();
    }
  
    applyPolicyFilter(isChecked: boolean, value: string) {
      value = value.toLowerCase();
      if (isChecked) {
        if (!this.policyFilter.includes(value)) {
          this.policyFilter.push(value);
        }
      } else {
        const index = this.policyFilter.indexOf(value);
        if (index >= 0) {
          this.policyFilter.splice(index, 1);
        }
      }
      this.applyFilter();
    }
  
    updateInfoCard() {
      this.product = this.bookFormGroup.value.productType;
      this.startDate = this.bookFormGroup.value.startDate;
      this.endDate = this.bookFormGroup.value.endDate
    }
  
    onSubmit() {
      this.isSubmitted = true;
      this.getReportList();
    }
  
    onToggle(event:any) {
      this.toggleValue = event.value;
    }
  
    clearInputField() {
      this.inputValue = '';
      this.queryData();
    }
  
    downloadPolicy(event:any) {
      const payload: downloadPDFGeneric = {
        PolicyNo: event.data.PolicyNumber,
        DealId: event.data.DealID
      }
      this.downloadService.downloadPDFGeneric(payload).subscribe({
        next: response => {
          if (response.StatusCode == Constants.statusCode_success && response.StatusMsg == "Success") {
            this.downloadService.downloadPdf(response.Data, event.data.PolicyNumber);
          }
        }
      })
    }
  
    private selectScrollListener!: () => void;
    @ViewChildren(MatSelect)
    selects!: QueryList<MatSelect>;
    handleMatSelectClick() {
      this.selectScrollListener = this.renderer.listen('window', 'scroll', () => {
        this.selects.forEach(select => {
          if (select.panelOpen) {
            select.close();
            this.removeMatSelectScrollListener();
          }
        });
      });
    }
  
    removeMatSelectScrollListener() {
      if (this.selectScrollListener) {
        this.selectScrollListener();
      }
    }
  
    onRefresh(status:any) {
      if (status) {
        this.getReportList();
      }
    }
  
    ngOnDestroy(): void {
      clearInterval(this.interval);
      window.removeEventListener('scroll', this.onWindowScroll.bind(this));
    }
  
  
}
