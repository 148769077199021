// Please place all labels in alphabetical order
//Place only those elements which can be used in multiple places in typescript file

export class Constants {
    static agent_ID = '100002862';
    static befitScope = 'esbzerotat'
    //Channel Types
    static channelNameMSITE = "NYSAHEALTH";
    static channelNameIpartner = "NYSAHEALTH";
    static homeScope = 'esbhome';
    static healthProposalProductCode = 'HADV';
    static healthProposalProductCodeHB = 'HBOOSTER';
    static healthProposalProductCodeAsp = 'ASP';
    static healthProposalProductCodeMP = 'CHI';
    static health = 'Health';
    static kycScope = 'esb-kyc';
    //Subproducts
    static subProductHap = '42';
    static subProductGS = '40';
    static subProductHEP = '33';
    static subProductASP = '22';
    static subProductMAX = '33';
    static subProductHB = '20';
    static subProductFS =  '23';
    static subProductCS =  '38';
    static pana = 'PANA';

    // error colors
    static color_error: string = 'red';
    static color_success: string = 'green';
    static color_warning: string = 'orange';

    // label
    static label_iciciLombard: string = "ICICI Lombard";

    // error
    static invalid_imId: string = 'Please provide IM ID';
    static invalid_Password: string = 'Please provide password';
    static errorLabel_invalidUnameAndPwd: string = 'Kindly enter correct username and password';
    static errorLabel_somethingWentWrong: string = "Something went wrong. Please try again later";

    //status codes
    static statusCode_success: number = 1;
    static statusCode_failure: number = 0;
    static statusCode_server_error: number = 500;
    static statusCode_unauthorized: number = 401;
    static statusCode_externalAPI: number = -1;
    static statusCode_Unhandeled: number = -2;
    static statusCode_Paylod: number = -3;
    static statusCode_Special_Char: number = -4;
    static statusCode_UnAuthorized: number = -5;
    static statusCode_Bad_Gateway: number = 502;
    static statusCode_service_unavailable: number = 503;
    static otherStatusCode: number = 8;

    // Regular expressions
    static numberPatternRegExp = /^\d+$/;
    static textPatternRegExp = /^[a-zA-Z ]+$/;
    static heightPatternRegExp = /^([1-7]|7\.)?(\.(\d)?)?$/;
    static weightPatternRegExp = /^[1-9]\d{0,2}$/;
    static namePatternRegExp = /^[a-zA-Z\s]*$/;
    static emailIdPatternRegExp = '\\S+@\\S+\\.\\w+';
    static mobileNumberPatternRegExp = '[6-9]\\d{9}';
    static panNumberPatternRegExp = '[A-Z]{5}[0-9]{4}[A-Z]{1}'

    // status messages
    static statusMsg_Success: string = 'success';
    static statusMsg_Manual: string = 'ACCEPTED_FOR_MANUAL_QC';
    static statusMsg_Closed: string = 'closed';
    static statusMsg_Unauthorized: string = '401';
    static statusMsg_Forbidden: string = '403';
    static statusMsg_BadRequest: string = '400';
    static statusMsg_SuccessCode: string = '200';
    static statusMsg_ServerError: string = '500';
    static statusMsg_NotImplemented: string = '501';
    static statusMsg_BadGateway: string = '502';
    static statusMsg_ServiceUnavailable: string = '503';

    // Session Time
    static idle = 1200;
    static timeOut = 0;
    static ping = 0;

    // Dashbaord
    static panaDashboard = 'Dashboard';
    static utilityDashboard = 'Logs';
}
