export const imagePath = {
    baseUrl : 'https://panaapi.icicilombard.com/BBR/',//'https://cldilmobilapp01.insurancearticlez.com/ipartnermsite/customComponentProj/',
    bbrImg: 'assets/images/BBR/BBR.svg',
    amountImg: 'assets/images/BBR/amount.svg',
    calendarRangeImg: 'assets/images/BBR/calendarRange.svg',
    cardListImg: 'assets/images/BBR/cardList.svg',
    CloseImg: 'assets/images/BBR/Close.svg',
    desktopCrossImg: 'assets/images/BBR/desktopCross.svg',
    downloadImg: 'assets/images/BBR/download.svg',
    downloadPolicyImg: 'assets/images/BBR/downloadPolicy.svg',
    EditImg: 'assets/images/BBR/Edit.svg',
    FilterImg: 'assets/images/BBR/Filter.svg',
    InfoImg: 'assets/images/BBR/Info.svg',
    NoRecordFoundImg: 'assets/images/BBR/NoRecordFound.svg',
    recordImg: 'assets/images/BBR/record.svg',
    ScrollToTopImg: 'assets/images/BBR/ScrollToTop.svg',
    SearchImg: 'assets/images/BBR/Search.svg',
    tablelistImg: 'assets/images/BBR/tablelist.svg',
    calendarImg: 'assets/images/calendar.png',
    minusImg: 'assets/images/BBR/minus.png',
    profileImg: 'assets/images/BBR/profile.png',
    crossImg: 'assets/images/cross.png',

}
