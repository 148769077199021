import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from '../../shared/services/utility.service';
import {ImagePathService} from '../../../../src/app/shared/services/image-path.service';

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss','../../../styles.scss','../../../assets/bootstrap.min.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class CustomTableComponent {
    @Input() reportList: any = [];
    @Input() buttonData:any = [];
    @Input() displayedColumns:any = [];
    @Input() tableOfBBR:boolean = false;
    @Output() policyNo = new EventEmitter<any>();
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    pageSizeOptions: number[] = [5, 10, 25, 100];
    pageSize: number = this.pageSizeOptions[0];
    pageIndex: number = 0;
    length: number = this.reportList.length;
    dataSource: MatTableDataSource<any> ;
    displayCol: any;
    selectedRows: any = [];
  
    constructor(
      public utilityService: UtilityService,
      public imagePathService:ImagePathService,
    ) {
      this.dataSource = new MatTableDataSource<any>(this.reportList);
      this.displayCol = this.displayedColumns.map((c:any) => c.columnDef);
    }
  
    ngOnChanges() {
      this.dataSource = new MatTableDataSource<any>(this.reportList);
      this.dataSource.paginator = this.paginator;
      this.displayCol = this.displayedColumns.map((c:any) => c.columnDef);
    }
  
    ngAfterViewInit() {
      this.dataSource.paginator = this.paginator;
    }
  
    onPageChange(event: PageEvent) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
    }
  
    emitData(data:any, identifier:any) {
      data = { data, identifier };
      this.policyNo.emit(data);
      if (identifier == 'Add To Pay') {
        this.applyPageSize(this.pageSizeOptions[3]);
      }
    }
  
    applyPageSize(pageSize: number) {
      this.pageSize = pageSize;
      this.dataSource.paginator = this.paginator;
    }
  
    evalConditionForMyPolicy(data:any, condition:any) {
      return eval(condition);
    }
  
    addSpace(inputString: string) {
      if (inputString == 'PendingForUnderWriter') {
        return 'Pending for Approval with UW1';
      }
      return inputString;
    }
  
    // emitSelectedRow(isChecked: boolean, row: any) {
    //   if (isChecked) {
    //     this.selectedRows.push(row);
    //   } else {
    //     if (this.selectedRows.length > 0) {
    //       for (let i = 0; i < this.selectedRows.length; i++) {
    //         if (this.selectedRows[i].PolicyNumber == row.PolicyNumber) {
    //           this.selectedRows.splice(i, 1);
    //         }
    //       }
    //     }
    //   }
    //   this.policyNo.emit({ data: this.selectedRows, identifier: 'Add To Pay' });
    // }
  
}
