import { Injectable } from '@angular/core';
import { environment } from '../../../../src/environments/environment';
import { imagePath } from '../modal/imagePath';

@Injectable({
  providedIn: 'root'
})
export class ImagePathService {
  scrollToTopImage = `${imagePath.baseUrl}${imagePath.ScrollToTopImg}`;
  bbrImg = `${imagePath.baseUrl}${imagePath.bbrImg}`;
  amountImg = `${imagePath.baseUrl}${imagePath.amountImg}`;
  calendarRangeImg = `${imagePath.baseUrl}${imagePath.calendarRangeImg}`;
  cardListImg = `${imagePath.baseUrl}${imagePath.cardListImg}`;
  CloseImg = `${imagePath.baseUrl}${imagePath.CloseImg}`;
  desktopCrossImg = `${imagePath.baseUrl}${imagePath.desktopCrossImg}`;
  downloadImg = `${imagePath.baseUrl}${imagePath.downloadImg}`;
  EditImg = `${imagePath.baseUrl}${imagePath.EditImg}`;
  FilterImg = `${imagePath.baseUrl}${imagePath.FilterImg}`;
  InfoImg = `${imagePath.baseUrl}${imagePath.InfoImg}`;
  NoRecordFoundImg = `${imagePath.baseUrl}${imagePath.NoRecordFoundImg}`;
  recordImg = `${imagePath.baseUrl}${imagePath.recordImg}`;
  ScrollToTopImg = `${imagePath.baseUrl}${imagePath.ScrollToTopImg}`;
  SearchImg = `${imagePath.baseUrl}${imagePath.SearchImg}`;
  tablelistImg = `${imagePath.baseUrl}${imagePath.tablelistImg}`;
  calendarImg = `${imagePath.baseUrl}${imagePath.calendarImg}`;
  minusImg = `${imagePath.baseUrl}${imagePath.minusImg}`;
  profileImg = `${imagePath.baseUrl}${imagePath.profileImg}`;

  constructor() { }

}
