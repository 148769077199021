export enum userEnums {
  AgentDetails = "Agdgdgygyvfgyvyg",
  LoginCredential = "Lrwsdcvgyhnkkkk",
  SubProductCode = 'subj1MgzZHLmtJIwde',
  ProductType = 'PTjiurmkkjdbaskjb',
  AuthToken = 'AXV0aFRva2VutoYXV0aFRv',
  UopqksmlN = 'UZV0sdasdfaaszAZV0sdasv',
  SessionReference = 'Swedfghjiuhgcxzxcvb',
  EncryptionText ='Ensdfghjk',
  ChannelData = 'CD0NMDvTRdwsxsm5wXWj',
}