import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../shared/services/generic.service';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BookedBusinessReportService {

  constructor(private generic: GenericService) { }

  readonly baseURI = `${environment.baseURL}`;
  readonly bookedBusinessReport = 'External/SasBookedBusiness' //'Intranet/SasBookedBusiness';
  readonly externalToken = 'apigateway/health/Intranet/CreateExternalToken'

  getBookedBusinessReport(data: any): Observable<any> {
    const endpoint = `${this.baseURI}${this.bookedBusinessReport}`;
    return this.generic.genericService<any>(endpoint, data);
  }
}
